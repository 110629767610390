import { Link, useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import config from "../Common/Configurations/APIConfig";
import LayoutHeader from "../Common/Layout/Header";
import LayoutSideBar from "../Common/Layout/Sidebar";
import { handleSuccess, handleError } from "../Common/Layout/CustomAlerts";
import Flatpickr from 'react-flatpickr'
import moment from "moment";
const initialFieldValues = {
    bookingRoomPriceId: "00000000-0000-0000-0000-000000000000",
    bookingId: "00000000-0000-0000-0000-000000000000",
    paymentMode: 'CASH',
    amount: 0,
    paymentDate: new Date()
};
export default function CheckInBookingDetails() {
    const params = useParams();
    const navigate = useNavigate();
    const [bookingsData, setBookingsData] = useState('');
    const [bookingRoomsData, setBookingRoomsData] = useState([]);
    const [bookingPriceData, setBookingPriceData] = useState([]);
    const [foodBill, setFoodBill] = useState(0);
    const [refundAmount, setRefundAmount] = useState(0);
    const [guestGST, setGuestGST] = useState('0');
    const [extraCharges, setExtraCharges] = useState(0);
    const [values, setValues] = useState(initialFieldValues);
    const [recordForEdit, setRecordForEdit] = useState(null);
    const [errors, setErrors] = useState({});
    const [extend, setExtend] = useState(false);
    const [igst, setIgst] = useState(false);
    const [checkoutDate, setCheckoutDate] = useState(new Date());
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    useEffect(() => {
        if (recordForEdit !== null) {
            setValues(recordForEdit);
        }
    }, [recordForEdit]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const selectCheckoutDate = (e, id) => {
        var cidate = moment(bookingsData.checkinDate).format('YYYY-MM-DD');
        var codate = moment(checkoutDate).format('YYYY-MM-DD');
        var todaydate = moment(new Date()).format('YYYY-MM-DD');
        if (codate < cidate) {
            alert("Checkout date should not be less than checkin date");
        }
        else if (codate < todaydate) {
            alert("Checkout date should not be less than todays date");
        }
        else {
            if (window.confirm('Are you sure to extend the room?')) {
                axios.post(config.APIACTIVATEURL + config.BOOKINGEXTEND + "?id=" + id + "&CheckoutDate=" + moment(checkoutDate).format('YYYY-MM-DD'), { ...headerconfig })
                    .then((response) => {
                        if (response.data.statusCode === 200) {
                            GetBooking();
                            GetBookingDetails();
                            GetPriceDetails();
                            setExtend(false)
                        }
                    });
            }
        }
    }
    const validate = () => {
        let temp = {};
        temp.paymentMode = values.paymentMode === "00000000-0000-0000-0000-000000000000" ? false : true;
        temp.amount = values.amount === 0 ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            const formData = new FormData()
            formData.append("bookingRoomPriceId", values.bookingRoomPriceId)
            formData.append("bookingId", params.bookingId)
            formData.append("paymentMode", values.paymentMode)
            formData.append("amount", values.amount)
            addOrEdit(formData);
        }
    };
    const applicationAPI = () => {
        return {
            create: (newrecord) =>
                axios.post(config.APIACTIVATEURL + config.CREATEBOOKINGPRICE, newrecord, { ...headerconfig }),
            update: (updateRecord) =>
                axios.put(config.APIACTIVATEURL + config.UPDATEBOOKINGPRICE, updateRecord, { ...headerconfig })
        };
    };
    const addOrEdit = (formData) => {
        if (formData.get('bookingRoomPriceId') === "00000000-0000-0000-0000-000000000000") {
            applicationAPI()
                .create(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Created");
                        resetForm();
                        GetBooking();
                        GetPriceDetails();
                    }
                    else {
                        handleError(res.data.message);
                    }
                });
        } else {
            applicationAPI()
                .update(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Updated");
                        resetForm();
                        GetBooking();
                        GetPriceDetails();
                    }
                    else {
                        handleError(res.data.message);
                    }
                });
        }
    };
    const resetForm = () => {
        setValues(initialFieldValues);
        setErrors({});
    };
    const showEditDetails = (data) => {
        setRecordForEdit(data);
    };
    const GetBooking = () => {
        axios
            .get(config.APIACTIVATEURL + config.GETBOOKINGID + "/" + params.bookingId, { ...headerconfig })
            .then((response) => {
                if (response.data.data !== undefined) {
                    setBookingsData(response.data.data);
                    setFoodBill(response.data.data.foodBill);
                    setExtraCharges(response.data.data.extraCharges);
                    setRefundAmount(response.data.data.refundAmount);
                    console.log(response.data.data.guestGST);
                    if (response.data.data.guestGST !== '') {
                        setGuestGST(response.data.data.guestGST);
                    }
                }
            });
    };
    const GetBookingDetails = () => {
        axios
            .get(config.APIACTIVATEURL + config.GETROOMDETAILBYBOOKINGID + "/" + params.bookingId, { ...headerconfig })
            .then((response) => {
                setBookingRoomsData(response.data.data);
            });
    };
    const GetPriceDetails = () => {
        axios
            .get(config.APIACTIVATEURL + config.GETROOMPRICEBYBOOKINGID + "/" + params.bookingId, { ...headerconfig })
            .then((response) => {
                setBookingPriceData(response.data.data);
            });
    };
    const onCheckout = (e, id) => {
        if (window.confirm('Are you sure to checkout the room?')) {
            var ggst = "0"
            if (guestGST !== "") {
                ggst = guestGST;
            }
            axios.post(config.APIACTIVATEURL + config.BOOKINGCHECKOUT + "?igst=" + igst + "&id=" + params.bookingId + "&Refund=" + refundAmount + "&GuestGST=" + ggst, { ...headerconfig })
                .then((response) => {
                    if (response.data.statusCode === 200) {
                        handleSuccess(response.data.data);
                        navigate("/checkoutbookingdetails/" + id);
                    }
                    else {
                        handleError(response.data.data);
                        GetBooking();
                    }
                });
        }
    }
    const handleBookingSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData()
        formData.append("bookingId", params.bookingId)
        formData.append("foodBill", foodBill)
        formData.append("extraCharges", extraCharges)
        axios.post(config.APIACTIVATEURL + config.BOOKINGCHARGES, formData, { ...headerconfig })
            .then((res) => {
                if (res.data.statusCode === 200) {
                    handleSuccess("Record Updated");
                    resetForm();
                    GetBooking();
                }
                else {
                    handleError(res.data.message);
                }
            });
    };
    const applyErrorClass = (field) =>
        field in errors && errors[field] === false ? " form-control-danger" : "";
    useEffect(() => {
        GetBooking();
        GetBookingDetails();
        GetPriceDetails();
    }, [])
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Booking Details</h4>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header align-items-center border-0 d-flex">
                            </div>
                            <div className="card-body p-0">
                                <table className="table table-bordered table-nowrap mb-0">
                                    <tr>
                                        <th className="text-nowrap p-3" scope="row">
                                            Reservation No
                                        </th>
                                        <td>
                                            {bookingsData.reservationNo}
                                        </td>
                                        <th className="text-nowrap" scope="row">
                                            Adults
                                        </th>
                                        <td>
                                            {bookingsData.adults}
                                        </td>
                                        <th className="text-nowrap" scope="row">
                                            Kids
                                        </th>
                                        <td>
                                            {bookingsData.kids}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="text-nowrap p-3" scope="row">
                                            Check-In Date
                                        </th>
                                        <td>
                                            {bookingsData.bookingStatus == "BOOKED" ? <span className="badge bg-danger"> NOT YET CHECK IN</span>
                                                : moment(bookingsData.checkinDate).format('DD/MM/YYYY  h:mm a')}
                                        </td>
                                        <th className="text-nowrap" scope="row">
                                            Check-Out Date
                                        </th>
                                        <td>
                                            {bookingsData.bookingStatus == "BOOKED" ? <span className="badge bg-danger"> NOT YET CHECK OUT</span>
                                                :
                                                extend === false ?
                                                    <Link className="edit-item-btn" onClick={e => setExtend(true)}>
                                                        {moment(bookingsData.checkoutDate).format('DD/MM/YYYY  h:mm a')}
                                                        <span className="badge bg-primary m-4">EXTEND ROOM</span>
                                                    </Link>
                                                    :
                                                    <div>
                                                        <div className="input-group">
                                                            <Flatpickr
                                                                className="form-control border-0 dash-filter-picker shadow"
                                                                options={{
                                                                    dateFormat: "d-m-Y"
                                                                }}
                                                                value={moment(bookingsData.checkoutDate).format('DD-MM-YYYY  h:mm a')}
                                                                onChange={(selectedDates) => {
                                                                    setCheckoutDate(selectedDates[0])
                                                                }}
                                                                minDate="today"
                                                                name="checkoutDate"
                                                            />
                                                            <div className="input-group-text bg-primary border-primary text-white">
                                                                <i className="ri-calendar-2-line" />
                                                            </div>
                                                        </div>
                                                        <div className="hstack gap-2 justify-content-end mb-3 mt-4">
                                                            <Link className="btn btn-sm btn-primary " onClick={e => selectCheckoutDate(e, bookingsData.bookingId)}><i className="mdi mdi-logout" />EXTEND</Link>
                                                            <Link className="btn btn-sm btn-danger " onClick={e => setExtend(false)}><i className="mdi mdi-logout" /> CANCEL</Link>
                                                        </div></div>
                                            }
                                        </td>
                                        <th className="text-nowrap" scope="row">
                                            Booking Date
                                        </th>
                                        <td>
                                            {moment(bookingsData.bookingDate).format('DD/MM/YYYY HH:mm a')}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="text-nowrap p-3" scope="row">
                                            Rooms
                                        </th>
                                        <td>
                                            {bookingsData.noOfRooms}
                                        </td>
                                        <th className="text-nowrap" scope="row">
                                            Nights
                                        </th>
                                        <td>
                                            {bookingsData.noOfNights}
                                        </td>
                                        <th className="text-nowrap" scope="row">
                                            Payment Mode
                                        </th>
                                        <td>
                                            {bookingsData.paymentMode}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="text-nowrap p-3" scope="row">
                                            Guest Name
                                        </th>
                                        <td>
                                            {bookingsData.guestName}
                                        </td>
                                        <th className="text-nowrap" scope="row">
                                            Guest Mobile
                                        </th>
                                        <td>
                                            {bookingsData.guestMobile}
                                        </td>
                                        <th className="text-nowrap" scope="row">
                                            Guest Email
                                        </th>
                                        <td>
                                            {bookingsData.guestEmail}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="text-nowrap p-3" scope="row">
                                            Room Prices
                                        </th>
                                        <td>
                                            {bookingsData.roomPrices} INR
                                        </td>
                                        <th className="text-nowrap" scope="row">
                                            Extra Charges
                                        </th>
                                        <td>
                                            {bookingsData.extraCharges} INR
                                        </td>
                                        <th className="text-nowrap" scope="row">
                                            Food Bill
                                        </th>
                                        <td>
                                            {bookingsData.foodBill} INR
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="text-nowrap p-3" scope="row">
                                            GST(12%)
                                        </th>
                                        <td>
                                            {bookingsData.gstAmount} INR
                                        </td>
                                        <th className="text-nowrap" scope="row">
                                            Final Price
                                        </th>
                                        <td>
                                            {bookingsData.finalPrice} INR
                                        </td>
                                        <th className="text-nowrap" scope="row">
                                            GST No
                                        </th>
                                        <td>
                                            <input className="form-control" style={{ border: '1px solid' }} value={guestGST} name="guestGST" onChange={e => setGuestGST(e.target.value)}></input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="text-nowrap p-3" scope="row">
                                            Total Paid
                                        </th>
                                        <td>
                                            {Math.round(bookingsData.paidAmount)} INR
                                        </td>
                                        <th className="text-nowrap" scope="row">
                                            Total Pending
                                        </th>
                                        <td>
                                            {Math.round(bookingsData.pendingAmount)} INR
                                        </td>
                                        <th className="text-nowrap" scope="row">
                                            Refund
                                        </th>
                                        <td>
                                            <input className="form-control" style={{ border: '1px solid' }} value={refundAmount} name="refundAmount" onChange={e => setRefundAmount(e.target.value)}></input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="text-nowrap p-3" scope="row">
                                            Payment Status
                                        </th>
                                        <td>
                                            <span className="badge bg-success">
                                                {bookingsData.paymentStatus}
                                            </span>
                                        </td>
                                        <th className="text-nowrap" scope="row">
                                            Booking Status
                                        </th>
                                        <td>
                                            <span className="badge bg-success">
                                                {bookingsData.bookingStatus}
                                            </span>
                                        </td>
                                        <th className="text-nowrap" scope="row">
                                            GST
                                        </th>
                                        <td>
                                            <select className="form-select" style={{ border: '1px solid' }} onChange={e => setIgst(e.target.value)}>
                                                <option value={true}>CGST/SGST</option>
                                                <option value={false}>IGST</option>
                                            </select>
                                        </td>
                                        <td>
                                            {bookingsData.bookingStatus !== "CHECKOUT" ?
                                                <div className="hstack gap-2 justify-content-end mb-3 mt-4">
                                                    <Link className="btn btn-danger " onClick={e => onCheckout(e, bookingsData.bookingId)}><i className="mdi mdi-logout" /> CHECK OUT</Link>
                                                </div>
                                                : ""
                                            }
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div className="alert alert-primary">
                            <form onSubmit={handleBookingSubmit} autoComplete="off" noValidate>
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <div className="mb-3">
                                                    <label htmlFor="foodBill" className="form-label">Total Food Bill</label>
                                                    <input type="number" value={foodBill} name="foodBill" placeholder="0" className="form-control" onChange={e => setFoodBill(e.target.value)} />
                                                    {errors.foodBill === false ? (<div className="validationerror">Please enter foodBill </div>) : ('')}
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="mb-3">
                                                    <label htmlFor="extraCharges" className="form-label">Total Extra Charges</label>
                                                    <input type="number" value={extraCharges} name="extraCharges" placeholder="0" className="form-control" onChange={e => setExtraCharges(e.target.value)} />
                                                </div>
                                            </div>
                                            {bookingsData.bookingStatus !== "CHECKOUT" ?
                                                <div className="col-lg-3">
                                                    <div className="hstack gap-2 justify-content-end mb-3 mt-4">
                                                        <button type="submit" className="btn btn-primary w-100">Submit</button>
                                                        <button type="button" className="btn btn-danger w-100" onClick={resetForm}>Cancel</button>
                                                    </div>
                                                </div> : ""
                                            }
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <table>
                                    <tr style={{ backgroundColor: "#0074d9", color: "#fff" }}>
                                        <th className="text-nowrap p-3" scope="row">
                                            Id
                                        </th>
                                        <th className="text-nowrap p-3" scope="row">
                                            Room No
                                        </th>
                                        <th className="text-nowrap p-3" scope="row">
                                            Room Price
                                        </th>
                                        <th className="text-nowrap p-3" scope="row">
                                            GST(12%)
                                        </th>
                                        <th className="text-nowrap p-3" scope="row">
                                            Total Price
                                        </th>
                                        <th></th>
                                    </tr>
                                    {bookingRoomsData.length > 0 && bookingRoomsData.map((bd, index) =>
                                        <tr key={bd.bookingId}>
                                            <td className="text-nowrap p-3" scope="row">
                                                {index + 1}
                                            </td>
                                            <td className="text-nowrap p-3" scope="row">
                                                {bd.roomNo}
                                            </td>
                                            <td className="text-nowrap p-3" scope="row">
                                                {bd.roomPrice} INR
                                            </td>
                                            <td className="text-nowrap p-3" scope="row">
                                                {bd.roomGST} INR
                                            </td>
                                            <td className="text-nowrap p-3" scope="row">
                                                {bd.totalRoomPrice} INR
                                            </td>
                                            <td className="text-nowrap p-3" scope="row"></td>
                                        </tr>
                                    )}
                                </table>
                            </div>
                            <div className="col-6">
                                <tr style={{ backgroundColor: "#0074d9", color: "#fff" }}>
                                    <th className="text-nowrap p-3" scope="row">
                                        Id
                                    </th>
                                    <th className="text-nowrap p-3" scope="row">
                                        Amount
                                    </th>
                                    <th className="text-nowrap p-3" scope="row">
                                        Payment Mode
                                    </th>
                                    <th className="text-nowrap p-3" scope="row">
                                        Payment Date
                                    </th>
                                    <th></th>
                                    <th></th>
                                </tr>
                                {bookingPriceData.length > 0 && bookingPriceData.map((bd, index) =>
                                    <tr key={bd.bookingId}>
                                        <td className="text-nowrap p-3" scope="row">
                                            {index + 1}
                                        </td>
                                        <td className="text-nowrap p-3" scope="row">
                                            {bd.amount} INR
                                        </td>
                                        <td className="text-nowrap p-3" scope="row">
                                            {bd.paymentMode}
                                        </td>
                                        <td className="text-nowrap p-3" scope="row">
                                            {moment(bd.paymentDate).format('DD/MM/YYYY HH:mm a')}
                                        </td>
                                        <td className="text-nowrap p-3" scope="row" colSpan={3}>

                                        </td>
                                    </tr>
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6"></div>
                            <div className="col-6">
                                {bookingsData.pendingAmount !== 0 ?
                                    <div className="alert alert-success">
                                        <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <div className="mb-3">
                                                        <label className="form-label">Payment Mode<span className="AsteriskRed">*</span></label>
                                                        <select className="form-select" name="paymentMode" value={values.paymentMode} onChange={handleInputChange}>
                                                            <option value="CASH">CASH</option>
                                                            <option value="UPI">UPI</option>
                                                            <option value="GOOGLEPAY">GOOGLE PAY</option>
                                                            <option value="PHONEPAY">PHONE PAY</option>
                                                            <option value="PAYTM">PAYTM</option>
                                                            <option value="NETBANKING">NET BANKING</option>
                                                            <option value="CREDITCARD">CREDIT CARD</option>
                                                            <option value="DEBITCARD">DEBIT CARD</option>
                                                            <option value="OYO">OYO</option>
                                                            <option value="OTHERS">OTHERS</option>
                                                        </select>
                                                        {errors.paymentMode === false ? (<div className="validationerror">Please enter payment mode </div>) : ('')}
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="mb-3">
                                                        <label htmlFor="amount" className="form-label">Amount</label>
                                                        <input type="text" value={values.amount} name="amount" onChange={handleInputChange} placeholder="0" className="form-control" />
                                                        {errors.amount === false ? (<div className="validationerror">Please enter amount </div>) : ('')}
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="hstack gap-2 justify-content-end mb-3 mt-4">
                                                        <button type="submit" className="btn btn-primary w-100">Submit</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    : ""}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}