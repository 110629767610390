import { Routes, Route } from 'react-router-dom';
import Login from './Authentication/Login';
import Logout from './Authentication/Logout';
import Dashboard from './Components/Dashboard';
import BedType from './Components/BedTypes';
import Rooms from './Components/Rooms';
import RoomPrices from './Components/RoomPrices';
import Bookings from './Components/Bookings';
import Amenities from './Components/Amentity';
import RoomTypes from './Components/RoomTypes';
import BookingDetails from './Components/BookingDetails';
import Transactions from './Components/Transactions';
import CashTransactions from './Components/CashTransactions';
import CheckinBookings from './Components/CheckIn';
import CheckoutBookings from './Components/Checkout';
import CheckInBookingDetails from './Components/CheckInBookingDetails';
import CheckOutBookingDetails from './Components/CheckOutBookingDetails';
import OYOBookings from './Components/OYOBookings';
import NewDashboard from './Components/NewDashboard';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/bedtypes" element={<BedType />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/newdashboard" element={<NewDashboard />} />
        <Route path="/rooms" element={<Rooms />} />
        <Route path="/roomprices" element={<RoomPrices />} />
        <Route path="/roomtypes" element={<RoomTypes />} />
        <Route path="/bookings" element={<Bookings />} />
        <Route path="/oyobookings" element={<OYOBookings />} />
        <Route path="/checkins" element={<CheckinBookings />} />
        <Route path="/checkouts" element={<CheckoutBookings />} />
        <Route path="/amenities" element={<Amenities />} />
        <Route path="/transactions" element={<Transactions />} />
        <Route path="/cashtransactions" element={<CashTransactions />} />
        <Route path="/bookingdetails/:bookingId" element={<BookingDetails />} />
        <Route path="/checkinbookingdetails/:bookingId" element={<CheckInBookingDetails />} />
        <Route path="/checkoutbookingdetails/:bookingId" element={<CheckOutBookingDetails />} />
      </Routes>
    </div>
  );
}

export default App;
