module.exports = {
    APILOCALURL: 'https://localhost:7295/api/',
    APISERVERURL: 'https://hoteligloos.azurewebsites.net/api/',
    APIACTIVATEURL: 'https://hoteligloos.azurewebsites.net/api/',
  
    
    LOGINUSER:'home/login',
  
    GETALLAMENITY: 'Amenity/GetAll',
    GETAMENITY: 'Amenity',
    CREATEAMENITY: 'Amenity/Create',
    UPDATEAMENITY: 'Amenity',
    DELETEAMENITY: 'Amenity',
    GETAMENITYID: 'Amenity',

    GETALLBEDTYPE: 'BedType/GetAll',
    GETBEDTYPE: 'BedType',
    CREATEBEDTYPE: 'BedType/Create',
    UPDATEBEDTYPE: 'BedType',
    DELETEBEDTYPE: 'BedType',
    GETBEDTYPEID: 'BedType',

    GETROOMSAVAILABILITY: 'Rooms/CheckRooms',
    GETALLROOMS: 'Rooms/GetAll',
    GETROOMS: 'Rooms',
    CREATEROOM: 'Rooms/Create',
    UPDATEROOM: 'Rooms',
    DELETEROOM: 'Rooms',
    GETROOMID: 'Rooms',

    GETALLROOMPRICES: 'RoomPrice/GetAll',
    GETROOMPRICES: 'RoomPrice',
    CREATEROOMPRICE: 'RoomPrice/Create',
    UPDATEROOMPRICE: 'RoomPrice',
    DELETEROOMPRICE: 'RoomPrice',
    GETROOMPRICEID: 'RoomPrice',
    GETROOMPRICEBYROOMID: 'RoomPrice/GetByRoom',

    GETALLROOMTYPES: 'RoomType/GetAll',
    GETROOMTYPES: 'RoomType',
    CREATEROOMTYPE: 'RoomType/Create',
    UPDATEROOMTYPE: 'RoomType',
    DELETEROOMTYPE: 'RoomType',
    GETROOMTYPEID: 'RoomType',

    GETALLBOOKINGS: 'Booking/GetAll',
    GETBOOKINGUESTS: 'Booking/GetBookingGuests',
    GETCHECKINGUESTS: 'Booking/GetCheckinGuests',
    GETCHECKOUTGUESTS: 'Booking/GetCheckoutGuests',
    BOOKINGEXTEND: 'Booking/Extend',
    GETALLBOOKINGDETAILS: 'Booking/BookingDetails',
    GETCASHTRANSACTION: 'Booking/CashTransactions',
    GETTRANSACTIONS: 'Booking/Transactions',
    GETGSTREPORT:'Booking/GSTReport',
    GETOYOBOOKINGS: 'Booking/Oyo',
    GETBOOKEDBOOKINGS: 'Booking/GetBooked',
    GETCHECKINBOOKINGS: 'Booking/GetCheckin',
    GETCHECKOUTBOOKINGS: 'Booking/GetCheckout',
    CREATEBOOKING: 'Booking/Create',
    CANCELOOKING: 'Booking/Cancel',
    DELETEBOOKING: 'Booking',
    CHECKINBOOKING: 'Booking/Checkin',
    GETBOOKINGID: 'Booking',
    BOOKINGCHECKOUT: 'Booking/Checkout',
    BOOKINGCHARGES: 'Booking/Charges',

    GETROOMPRICEBYBOOKINGID: 'BookingRoomPrice/GetByBookingId',
    GETROOMPRICEBYID: 'BookingRoomPrice',
    CREATEBOOKINGPRICE: 'BookingRoomPrice/Create',
    UPDATEBOOKINGPRICE: 'BookingRoomPrice',
    DELETEBOOKINGPRICE: 'BookingRoomPrice',

    GETROOMDETAILBYBOOKINGID: 'BookingRoomDetail/GetByBookingId',
    GETBOOKINGDETAILBYID: 'BookingRoomDetail',
    CREATEBOOKINGROOMDETAIL: 'BookingRoomDetail/Create',
    UPDATEBOOKINGROOMDETAIL: 'BookingRoomDetail',
    DELETEBOOKINGROOMDETAIL: 'BookingRoomDetail'

  }