import { Link, useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import axios from 'axios';
import config from "../Common/Configurations/APIConfig";
import LayoutHeader from "../Common/Layout/Header";
import LayoutSideBar from "../Common/Layout/Sidebar";
import { handleSuccess, handleError } from "../Common/Layout/CustomAlerts";
import moment from "moment";
import { useReactToPrint } from 'react-to-print';
import { PrintReceipt } from "./PrintReceipt";

export default function CheckOutBookingDetails() {
    const params = useParams();
    const [bookingsData, setBookingsData] = useState('');
    const [bookingRoomsData, setBookingRoomsData] = useState('');
    const [bookingPriceData, setBookingPriceData] = useState('');
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    const GetBooking = () => {
        axios
            .get(config.APIACTIVATEURL + config.GETBOOKINGID + "/" + params.bookingId, { ...headerconfig })
            .then((response) => {
                setBookingsData(response.data.data);
            });
    };
    const GetBookingDetails = () => {
        axios
            .get(config.APIACTIVATEURL + config.GETALLBOOKINGDETAILS + "/" + params.bookingId, { ...headerconfig })
            .then((response) => {
                setBookingRoomsData(response.data.data);
            });
    };
    const GetPriceDetails = () => {
        axios
            .get(config.APIACTIVATEURL + config.GETROOMPRICEBYBOOKINGID + "/" + params.bookingId, { ...headerconfig })
            .then((response) => {
                setBookingPriceData(response.data.data);
            });
    };
    useEffect(() => {
        GetBooking();
        GetBookingDetails();
        GetPriceDetails();
    }, [])
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">CHECKOUT DETAILS</h4>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header align-items-center border-0 d-flex">
                            </div>
                            <div className="card-body p-0">
                                <div className="row text-nowrap p-3">
                                    <div className="col-2">
                                        <button onClick={handlePrint} className="btn btn-primary">Print</button>
                                    </div>
                                    <div className="col-10"></div>
                                </div>
                                <table className="table table-bordered table-nowrap mb-0">
                                    <tr>
                                        <th className="text-nowrap p-3" scope="row">
                                            Reservation No
                                        </th>
                                        <td>
                                            {bookingsData.reservationNo}
                                        </td>
                                        <th className="text-nowrap" scope="row">
                                            Adults
                                        </th>
                                        <td>
                                            {bookingsData.adults}
                                        </td>
                                        <th className="text-nowrap" scope="row">
                                            Kids
                                        </th>
                                        <td>
                                            {bookingsData.kids}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="text-nowrap p-3" scope="row">
                                            Check-In Date
                                        </th>
                                        <td>
                                            {bookingsData.bookingStatus == "BOOKED" ? <span className="badge bg-danger"> NOT YET CHECK IN</span>
                                                : moment(bookingsData.checkinDate).format('DD/MM/YYYY HH:mm a')}
                                        </td>
                                        <th className="text-nowrap" scope="row">
                                            Check-Out Date
                                        </th>
                                        <td>
                                            {bookingsData.bookingStatus !== "CHECKOUT" ? <span className="badge bg-danger"> NOT YET CHECK OUT</span>
                                                : moment(bookingsData.checkoutDate).format('DD/MM/YYYY HH:mm a')}
                                        </td>
                                        <th className="text-nowrap" scope="row">
                                            Booking Date
                                        </th>
                                        <td>
                                            {moment(bookingsData.bookingDate).format('DD/MM/YYYY HH:mm a')}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="text-nowrap p-3" scope="row">
                                            Rooms
                                        </th>
                                        <td>
                                            {bookingsData.noOfRooms}
                                        </td>
                                        <th className="text-nowrap" scope="row">
                                            Nights
                                        </th>
                                        <td>
                                            {bookingsData.noOfNights}
                                        </td>
                                        <th className="text-nowrap" scope="row">
                                            Payment Mode
                                        </th>
                                        <td>
                                            {bookingsData.paymentMode}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="text-nowrap p-3" scope="row">
                                            Guest Name
                                        </th>
                                        <td>
                                            {bookingsData.guestName}
                                        </td>
                                        <th className="text-nowrap" scope="row">
                                            Guest Mobile
                                        </th>
                                        <td>
                                            {bookingsData.guestMobile}
                                        </td>
                                        <th className="text-nowrap p-3" scope="row">
                                            Guest Email
                                        </th>
                                        <td>
                                            {bookingsData.guestEmail}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="text-nowrap p-3" scope="row">
                                            Room Prices
                                        </th>
                                        <td>
                                            {bookingsData.roomPrices} INR
                                        </td>
                                        <th className="text-nowrap" scope="row">
                                            GST(12%)
                                        </th>
                                        <td>
                                            {bookingsData.gstAmount} INR
                                        </td>
                                        <th className="text-nowrap p-3" scope="row">
                                            Final Price
                                        </th>
                                        <td>
                                            {bookingsData.finalPrice} INR
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="text-nowrap p-3" scope="row">
                                            Payment Status
                                        </th>
                                        <td>
                                            <span className="badge bg-success">
                                                {bookingsData.paymentStatus}
                                            </span>
                                        </td>
                                        <th className="text-nowrap" scope="row">
                                            Booking Status
                                        </th>
                                        <td>
                                            <span className="badge bg-success">
                                                {bookingsData.bookingStatus}
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <table>
                                        <tr style={{ backgroundColor: "#0074d9", color: "#fff" }}>
                                            <th className="text-nowrap p-3" scope="row">
                                                Id
                                            </th>
                                            <th className="text-nowrap p-3" scope="row">
                                                Room No
                                            </th>
                                            <th className="text-nowrap p-3" scope="row">
                                                Room Price
                                            </th>
                                            <th className="text-nowrap p-3" scope="row">
                                                GST(12%)
                                            </th>
                                            <th className="text-nowrap p-3" scope="row">
                                                Total Price
                                            </th>
                                            <th></th>
                                        </tr>
                                        {bookingRoomsData.length > 0 && bookingRoomsData.map((bd, index) =>
                                            <tr key={bd.bookingId}>
                                                <td className="text-nowrap p-3" scope="row">
                                                    {index + 1}
                                                </td>
                                                <td className="text-nowrap p-3" scope="row">
                                                    {bd.roomNo}
                                                </td>
                                                <td className="text-nowrap p-3" scope="row">
                                                    {bd.roomPrice} INR
                                                </td>
                                                <td className="text-nowrap p-3" scope="row">
                                                    {bd.roomGST} INR
                                                </td>
                                                <td className="text-nowrap p-3" scope="row">
                                                    {bd.totalRoomPrice} INR
                                                </td>
                                                <td className="text-nowrap p-3" scope="row"></td>
                                            </tr>
                                        )}
                                    </table>
                                </div>
                                <div className="col-6">
                                    <table>
                                        <tr style={{ backgroundColor: "#0074d9", color: "#fff" }}>
                                            <th className="text-nowrap p-3" scope="row">
                                                Id
                                            </th>
                                            <th className="text-nowrap p-3" scope="row">
                                                Amount
                                            </th>
                                            <th className="text-nowrap p-3" scope="row">
                                                Payment Mode
                                            </th>
                                            <th className="text-nowrap p-3" scope="row">
                                                Payment Date
                                            </th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                        {bookingPriceData.length > 0 && bookingPriceData.map((bd, index) =>
                                            <tr key={bd.bookingId}>
                                                <td className="text-nowrap p-3" scope="row">
                                                    {index + 1}
                                                </td>
                                                <td className="text-nowrap p-3" scope="row">
                                                    {bd.amount} INR
                                                </td>
                                                <td className="text-nowrap p-3" scope="row">
                                                    {bd.paymentMode}
                                                </td>
                                                <td className="text-nowrap p-3" scope="row">
                                                    {moment(bd.paymentDate).format('DD/MM/YYYY HH:mm a')}
                                                </td>
                                                <td className="text-nowrap p-3" scope="row" colSpan={3}>

                                                </td>
                                            </tr>
                                        )}
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ display: "none" }}>
                <PrintReceipt ref={componentRef} bookingId={params.bookingId}></PrintReceipt>
            </div>
        </div>
    );
}