import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import config from "../Common/Configurations/APIConfig";
import { handleSuccess, handleError } from "../Common/Layout/CustomAlerts";
import LayoutHeader from "../Common/Layout/Header";
import LayoutSideBar from "../Common/Layout/Sidebar";
import moment from "moment";
export default function OYOBookings() {
    const [bookingsData, setBookingsData] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [pageNumber, setPageNumber] = useState(1);
    const [data, setData] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    const GetLastPageData = () => {
        GetBookings(totalPages)
    }
    const GetFirstPageData = () => {
        GetBookings("1")
    }
    const GetPageData = (number) => {
        setPageNumber(number);
        if (pageNumber !== number)
        GetBookings(number)
    }
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }
    const renderPageNumbers = pageNumbers.map((number) => {
        return (
            <li className="page-item" key={number} id={number} onClick={() => GetPageData(number)}>
                <Link className="page-link">{number}</Link>
            </li>
        );
    });
    const onDelete = (e, id) => {
        if (window.confirm('Are you sure to delete this record?')) {
            axios.delete(config.APIACTIVATEURL + config.DELETEBOOKING + "/" + id, { ...headerconfig })
                .then((response) => {
                    if (response.data.statusCode === 200) {
                        handleSuccess(response.data.data);
                        GetBookings(pageNumber);
                    }
                    else {
                        handleError(response.data.data);
                        GetBookings(pageNumber);
                    }
                });
        }
    }
    const GetBookings = (number) => {
        axios
            .get(config.APIACTIVATEURL + config.GETOYOBOOKINGS + "?pageNumber=" + number + "&pageSize=" + pageSize + "", { ...headerconfig })
            .then((response) => {
                setBookingsData(response.data.data.data);
                setPageNumber(response.data.data.pageNumber);
                setPageSize(response.data.data.pageSize);
                setTotalPages(response.data.data.totalPages);
                setData(response.data.data);
                setTotalRecords(response.data.data.totalRecords);
            });
    };
    useEffect(() => {
        GetBookings(pageNumber);
    }, [])
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Bookings</h4>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card card-height-100">
                                    <div className="card-header align-items-center d-flex">
                                        <h4 className="card-title mb-0 flex-grow-1">Bookings</h4>
                                    </div>{/* end card header */}
                                    <div className="card-body">
                                        <div className="table-responsive table-card">
                                            <table className="table align-middle" id="customerTable">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th>Id</th>
                                                        <th>Reservation No</th>
                                                        <th>Check-in</th>
                                                        <th>Check-out</th>
                                                        <th>Guests</th>
                                                        <th>Name</th>
                                                        <th>Mobile</th>
                                                        <th>Rooms</th>
                                                        <th>Payment Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    {bookingsData.length > 0 && bookingsData.map((bd, index) =>
                                                        <tr key={bd.bookingId}>
                                                            <td>{index + 1}</td>
                                                            <td className="id"><Link to={"/bookingdetails/" + bd.bookingId}>{bd.reservationNo}</Link></td>
                                                            <td className="id">{moment(bd.checkinDate).format('DD/MM/YYYY')}</td>
                                                            <td className="id">{moment(bd.checkoutDate).format('DD/MM/YYYY')}</td>
                                                            <td className="id">Adults : {bd.adults}, Kids: {bd.kids}</td>
                                                            <td className="id">{bd.guestName}</td>
                                                            <td className="id">{bd.guestMobile}</td>
                                                            <td className="id">{bd.noOfRooms}</td>
                                                            <td className="id">{bd.paymentStatus}</td>
                                                            <td>
                                                                <ul className="list-inline hstack gap-2 mb-0">
                                                                    <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Delete">
                                                                        <Link className="btn btn-sm btn-primary" to={"/bookingdetails/" + bd.bookingId}>
                                                                            <i className="ri-eye-2-line align-bottom text-white" /> View
                                                                        </Link>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="align-items-center mt-4 pt-2 justify-content-between d-flex">
                                            <div className="flex-shrink-0">
                                                <div className="text-muted">
                                                    Showing <span className="fw-semibold">{bookingsData.length}</span> of <span className="fw-semibold">{totalRecords}</span> Results
                                                </div>
                                            </div>
                                            <ul className="pagination pagination-separated pagination-sm mb-0">
                                                <li className={"page-item" + data.previousPage === null ? 'disabled' : ''} onClick={() => GetFirstPageData()}>
                                                    <Link className="page-link">Previous</Link>
                                                </li>
                                                {renderPageNumbers}
                                                <li className={"page-item" + data.nextPage === null ? 'disabled' : ''} onClick={() => GetLastPageData()}>
                                                    <Link className="page-link">Next</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> {/* .card*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}