import React, { useState, useEffect } from "react";
import axios from 'axios';
import config from "../Common/Configurations/APIConfig";
import moment from "moment";
export const PrintReceipt = React.forwardRef((props, ref) => {
    const [bookingsData, setBookingsData] = useState('');
    const [bookingRoomsData, setBookingRoomsData] = useState('');
    const [extraCharges, setExtraCharges] = useState('');
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    const GetBooking = () => {
        axios
            .get(config.APIACTIVATEURL + config.GETBOOKINGID + "/" + props.bookingId, { ...headerconfig })
            .then((response) => {
                setBookingsData(response.data.data);
            });
    };
    const GetBookingDetails = () => {
        axios
            .get(config.APIACTIVATEURL + config.GETALLBOOKINGDETAILS + "/" + props.bookingId, { ...headerconfig })
            .then((response) => {
                setBookingRoomsData(response.data.data);
            });
    };
    useEffect(() => {
        GetBooking();
        GetBookingDetails();
    }, [])
    return (
        <div ref={ref}>
            <div className="card">
                <div className="card-header align-items-center border-0 d-flex">
                    <table style={{ width: "100%", paddingRight: "20px" }}>
                        <tr>
                            <th className="text-nowrap" scope="row" rowSpan={7} style={{ textAlign: "left" }}><img src="/assets/images/logo.png" style={{ maxWidth: "100px", maxHeight: "100px" }}></img></th>
                        </tr>
                        <tr>
                            <th className="text-nowrap" scope="row" style={{ textAlign: "right" }}>SURAAG HOTELS AND RESORTS</th>
                        </tr>
                        <tr>
                            <th className="text-nowrap" scope="row" style={{ textAlign: "right" }}>3-35, HANUMAN SAI NAGAR, BESIDE CMR SHOPPING MALL</th>
                        </tr>
                        <tr>
                            <th className="text-nowrap" scope="row" style={{ textAlign: "right" }}>MAIN ROAD, UPPAL,Hyderabad, Telangana</th>
                        </tr>
                        <tr>
                            <th className="text-nowrap" scope="row" style={{ textAlign: "right" }}>+91 40 4950 5357</th>
                        </tr>
                        <tr>
                            <th className="text-nowrap" scope="row" style={{ textAlign: "right" }}>GST 36AEYFS1817F1ZM</th>
                        </tr>
                    </table>
                </div>
                <div className="card-body p-0">
                    <table className="table table-nowrap mb-0">
                        <tr>
                            <th colSpan={5} style={{ textAlign: "center" }}><h5><b>BILL RECEIPT</b></h5></th>
                            <th style={{ textAlign: "center" }}>Bill Date : {moment(bookingsData.checkoutDate).format('DD/MM/YYYY')}</th>
                        </tr>
                        <tr>
                            <th className="text-nowrap p-3" scope="row">
                                Invoice No
                            </th>
                            <td>
                                {bookingsData.invoiceNo}
                            </td>
                            <th className="text-nowrap p-3" scope="row">
                                Guest Name
                            </th>
                            <td>
                                {bookingsData.guestName}
                            </td>
                        </tr>
                        <tr>
                            <th className="text-nowrap p-3" scope="row">
                                Mobile
                            </th>
                            <td>
                                {bookingsData.guestMobile}
                            </td>
                            <th className="text-nowrap p-3" scope="row">
                                Email
                            </th>
                            <td>
                                {bookingsData.guestEmail}
                            </td>
                            <th className="text-nowrap p-3" scope="row">
                                Nights
                            </th>
                            <td>
                                {bookingsData.noOfNights}
                            </td>
                        </tr>
                        <tr>
                            <th className="text-nowrap p-3" scope="row">
                                GST
                            </th>
                            <td>
                                {bookingsData.guestGST}
                            </td>
                        </tr>
                        <tr>
                            <th className="text-nowrap p-3" scope="row">
                                Check-In Date
                            </th>
                            <td>
                                {bookingsData.bookingStatus == "BOOKED" ? <span className="badge bg-danger"> NOT YET CHECK IN</span>
                                    : moment(bookingsData.checkinDate).format('DD/MM/YYYY')}
                            </td>
                            <th className="text-nowrap" scope="row">
                                Check-Out Date
                            </th>
                            <td>
                                {bookingsData.bookingStatus !== "CHECKOUT" ? <span className="badge bg-danger"> NOT YET CHECK OUT</span>
                                    : moment(bookingsData.checkoutDate).format('DD/MM/YYYY')}
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div className="card-header align-items-center border-0 d-flex">
                <table style={{ width: "100%" }}>
                    <tr style={{ backgroundColor: "#000", color: "#fff" }}>
                        <th className="text-nowrap p-3" scope="row">
                            Id
                        </th>
                        <th className="text-nowrap p-3" scope="row">
                            Room Type
                        </th>
                        <th className="text-nowrap p-3" scope="row">
                            Room Price
                        </th>
                        <th className="text-nowrap p-3" scope="row">
                            GST(12%)
                        </th>
                        <th className="text-nowrap p-3" scope="row">
                            Total Price
                        </th>
                        <th></th>
                    </tr>
                    {bookingRoomsData.length > 0 && bookingRoomsData.map((bd, index) =>
                        <tr key={bd.bookingId}>
                            <td className="text-nowrap p-3" scope="row">
                                {index + 1}
                            </td>
                            <td className="text-nowrap p-3" scope="row">
                                {bd.roomTypeName}
                            </td>
                            <td className="text-nowrap p-3" scope="row">
                                {bd.roomPrice} INR
                            </td>
                            <td className="text-nowrap p-3" scope="row">
                                {bd.roomGST} INR
                            </td>
                            <td className="text-nowrap p-3" scope="row">
                                {bd.totalRoomPrice} INR
                            </td>
                            <td className="text-nowrap p-3" scope="row"></td>
                        </tr>
                    )}
                </table>
            </div>
            <div className="card" style={{ marginRight: "50px" }}>
                <div className="card-header align-items-center border-0 d-flex">
                    <table style={{ width: "100%" }}>
                        <tr>
                            <th colSpan={2} className="text-nowrap" scope="row" style={{ textAlign: "left" }}>
                                SURAAG HOTELS AND RESORTS
                            </th>
                            <th colSpan={4}></th>
                            <th className="text-nowrap" scope="row" style={{ textAlign: "right" }}>
                                Net Price :
                            </th>
                            <th className="text-nowrap" scope="row" style={{ textAlign: "right" }}>
                                {bookingsData.roomPrices}
                            </th>
                        </tr>
                        <tr>
                            <th colSpan={2} className="text-nowrap" scope="row" style={{ textAlign: "left" }}>
                                HDFC BANK, UPPAL BRANCH
                            </th>
                            <th colSpan={4}></th>
                            <th className="text-nowrap" scope="row" style={{ textAlign: "right" }}>
                                Food Bill :
                            </th>
                            <th className="text-nowrap" scope="row" style={{ textAlign: "right" }}>
                                {bookingsData.foodBill}
                            </th>
                        </tr>
                        <tr>
                            <th colSpan={2} className="text-nowrap" scope="row" style={{ textAlign: "left" }}>
                                IFSC :HDFC0001042
                            </th>
                            <th colSpan={4}></th>
                            <th className="text-nowrap" scope="row" style={{ textAlign: "right" }}>
                                Extra Charges :
                            </th>
                            <th className="text-nowrap" scope="row" style={{ textAlign: "right" }}>
                                {bookingsData.extraCharges}
                            </th>
                        </tr>
                        <tr>
                            <th colSpan={2} className="text-nowrap" scope="row" style={{ textAlign: "left" }}>
                                ACCOUNT No:50200088732897
                            </th>
                            <th colSpan={4}></th>
                            <th className="text-nowrap" scope="row" style={{ textAlign: "right" }}>
                                SGST(6%) :
                            </th>
                            <th className="text-nowrap" scope="row" style={{ textAlign: "right" }}>
                                {bookingsData.igst === false ? Math.round(bookingsData.gstAmount / 2) : 0}
                            </th>
                        </tr>
                        <tr>
                            <th colSpan={6}></th>
                            <th className="text-nowrap" scope="row" style={{ textAlign: "right" }}>
                                CGST(6%) :
                            </th>
                            <th className="text-nowrap" scope="row" style={{ textAlign: "right" }}>
                                {bookingsData.igst === false ? Math.round(bookingsData.gstAmount / 2) : 0}
                            </th>
                        </tr>
                        <tr>
                            <th colSpan={6}></th>
                            <th className="text-nowrap" scope="row" style={{ textAlign: "right" }}>
                                IGST(12%)
                            </th>
                            <th className="text-nowrap" scope="row" style={{ textAlign: "right" }}>
                                {bookingsData.igst === true ? bookingsData.gstAmount : 0}
                            </th>
                        </tr>
                        <tr>
                            <th colSpan={6}></th>
                            <th className="text-nowrap" scope="row" style={{ textAlign: "right" }}>
                                Total Amount (Rs.) :
                            </th>
                            <th className="text-nowrap" scope="row" style={{ textAlign: "right" }}>
                                {bookingsData.finalPrice}
                            </th>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    );
});