import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import config from "../Common/Configurations/APIConfig";
import { handleSuccess, handleError } from "../Common/Layout/CustomAlerts";
import LayoutHeader from "../Common/Layout/Header";
import LayoutSideBar from "../Common/Layout/Sidebar";
import Flatpickr from "react-flatpickr";
import moment from "moment";
const initialFieldValues = {
    bookingId: "00000000-0000-0000-0000-000000000000",
    reservationNo: "",
    checkinDate: new Date(),
    checkoutDate: new Date(),
    noOfNights: 1,
    adults: 1,
    kids: 0,
    noOfRooms: 0,
    guestName: "",
    guestEmail: "",
    guestMobile: "",
    guestGST: "",
    roomPrices: 0,
    roomGST: 0,
    totalRoomPrice: 0,
    extraCharges: 0,
    foodBill: 0,
    price: 0,
    gSTAmount: 0,
    gST: 12,
    finalPrice: 0,
    bookingDate: new Date(),
    bookingStatus: "CHECKIN",
    paymentStatus: "PENDING",
    paymentMode: "CASH",
    source: "WALKIN",
    pendingAmount: 0,
    paidAmount: 0
};
export default function Dashboard() {
    const minDateValue = new Date();
    const [rooms, setRooms] = useState([]);
    const [values, setValues] = useState(initialFieldValues);
    const [errors, setErrors] = useState({});
    const [savePrice, setSavePrice] = useState(0);
    const [saveTotalPrice, setSaveTotalPrice] = useState(0);
    const [bookingRoomsData, setBookingRoomsData] = useState([]);
    const [btnSubmit, setBtnSubmit] = useState(true);
    const [loading, setLoading] = useState(true);
    const [roomSelect, setRoomSelect] = useState(true);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
            'Content-Type': 'application/json',
        }
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const validate = () => {
        let temp = {};
        temp.guestName = values.guestName === "" ? false : true;
        temp.guestMobile = values.guestMobile === "" ? false : true;
        temp.roomPrices = values.roomPrices === 0 ? false : true;
        //temp.paidAmount = values.paidAmount === 0 ? false : true;
        temp.totalRoomPrice = values.totalRoomPrice === 0 ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const ReserverBooking = (e) => {
        e.preventDefault();
        if (values.roomPrices === 0) {
            alert("Please select rooms");
        }
        else if (window.confirm('Are you sure to reserve the room(s)?')) {
            if (validate()) {
                const formData = new FormData()
                formData.append("bookingId", values.bookingId)
                formData.append("reservationNo", values.reservationNo)
                formData.append("checkinDate", moment.utc(values.bookingDate).format('YYYY-MM-DDTHH:mm:ss[Z]'))
                formData.append("checkoutDate", moment.utc(values.checkoutDate).format('YYYY-MM-DDTHH:mm:ss[Z]'))
                formData.append("noOfNights", values.noOfNights)
                formData.append("adults", values.adults)
                formData.append("kids", values.kids)
                formData.append("noOfRooms", values.noOfRooms)
                formData.append("guestName", values.guestName)
                formData.append("guestEmail", values.guestEmail)
                formData.append("guestMobile", values.guestMobile)
                formData.append("guestGST", values.guestGST)
                formData.append("roomPrices", values.roomPrices)
                formData.append("roomGST", values.roomGST)
                formData.append("totalRoomPrice", values.totalRoomPrice)
                formData.append("extraCharges", values.extraCharges)
                formData.append("foodBill", values.foodBill)
                formData.append("price", values.price)
                formData.append("gSTAmount", values.gSTAmount)
                formData.append("gST", values.gST)
                formData.append("finalPrice", values.finalPrice)
                formData.append("bookingDate", moment(values.bookingDate).format('YYYY-MM-DD'))
                formData.append("bookingStatus", "BOOKED")
                formData.append("paymentStatus", values.paymentStatus)
                formData.append("paymentMode", values.paymentMode)
                formData.append("source", values.source)
                formData.append("paidAmount", Math.round(values.paidAmount))
                formData.append("pendingAmount", Math.round(values.pendingAmount))
                formData.append("bookingRoomsData", JSON.stringify(bookingRoomsData))
                addOrEdit(formData);
            }
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (values.roomPrices === 0) {
            alert("Please select rooms");
        }
        else if (window.confirm('Are you sure to check-in the room(s)?')) {
            if (validate()) {
                const formData = new FormData()
                formData.append("bookingId", values.bookingId)
                formData.append("reservationNo", values.reservationNo)
                formData.append("checkinDate", moment.utc(values.checkinDate).format('YYYY-MM-DDTHH:mm:ss[Z]'))
                formData.append("checkoutDate", moment.utc(values.checkoutDate).format('YYYY-MM-DDTHH:mm:ss[Z]'))
                formData.append("noOfNights", values.noOfNights)
                formData.append("adults", values.adults)
                formData.append("kids", values.kids)
                formData.append("noOfRooms", values.noOfRooms)
                formData.append("guestName", values.guestName)
                formData.append("guestEmail", values.guestEmail)
                formData.append("guestMobile", values.guestMobile)
                formData.append("guestGST", values.guestGST)
                formData.append("roomPrices", values.roomPrices)
                formData.append("roomGST", values.roomGST)
                formData.append("totalRoomPrice", values.totalRoomPrice)
                formData.append("extraCharges", values.extraCharges)
                formData.append("foodBill", values.foodBill)
                formData.append("price", values.price)
                formData.append("gSTAmount", values.gSTAmount)
                formData.append("gST", values.gST)
                formData.append("finalPrice", values.finalPrice)
                formData.append("bookingDate", moment(values.bookingDate).format('YYYY-MM-DD'))
                formData.append("bookingStatus", "CHECKIN")
                formData.append("paymentStatus", values.paymentStatus)
                formData.append("paymentMode", values.paymentMode)
                formData.append("source", values.source)
                formData.append("paidAmount", values.paidAmount)
                formData.append("pendingAmount", values.pendingAmount)
                formData.append("bookingRoomsData", JSON.stringify(bookingRoomsData))
                addOrEdit(formData);
            }
        }
    };
    const addOrEdit = (formData) => {
        if (formData.get('bookingId') === "00000000-0000-0000-0000-000000000000") {
            applicationAPI()
                .create(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Created");
                        resetForm();
                        GetRooms(new Date());
                    }
                    else {
                        handleError(res.data.message);
                    }
                });
        }
    };
    const resetForm = () => {
        setValues(initialFieldValues);
        setErrors({});
        setSavePrice(0);
        setBookingRoomsData([]);
        /* var tdate = new Date();
        setValues({
            ...values,
            ['checkinDate']: new Date(),
            ['checkoutDate']: tdate.getDate() + 1
        }); */
    };
    const applicationAPI = () => {
        return {
            create: (newrecord) =>
                axios.post(config.APIACTIVATEURL + config.CREATEBOOKING, newrecord, { ...headerconfig })
        };
    };
    function checkselectedroom(bookingRoomsData, data) {
        if (bookingRoomsData.length > 0) {
            const existing = bookingRoomsData.find(employee => employee.roomId === data.roomId);
            if (existing) {
                return false
            } else { return true }
        }
        else {
            return true;
        }
    }
    const selectRoom = (data) => {
        if (checkselectedroom(bookingRoomsData, data)) {
            let bdata = [...bookingRoomsData, data];
            setBookingRoomsData(bdata);
            const nights = parseFloat(values.noOfNights);
            var temproomprice = savePrice;
            temproomprice = temproomprice + parseFloat(data.roomPrice);
            setSavePrice(temproomprice);
            var rp = parseFloat(temproomprice * nights);
            var gst = parseFloat((rp * 12) / 100);
            var trp = rp + gst;
            setSaveTotalPrice(trp);
            var extraCharges = parseFloat(values.extraCharges);
            var foodBill = parseFloat(values.foodBill);
            var price = parseFloat(rp + extraCharges + foodBill);
            var gSTAmount = parseFloat((price * 12) / 100);
            var finalPrice = price + gSTAmount;
            setValues({
                ...values,
                ['roomPrices']: rp,
                ['roomGST']: gst,
                ['totalRoomPrice']: trp,
                ['price']: price,
                ['extraCharges']: extraCharges,
                ['foodBill']: foodBill,
                ['gSTAmount']: gSTAmount,
                ['finalPrice']: finalPrice,
                ['pendingAmount']: finalPrice
            });
        }
        else {
            alert("Room already selected");
        }
    };
    const showBooking = (data) => {
        alert("Room already booked. Please check another room")
    };
    const addDays = (cindate) => {
        if (cindate === undefined) {
            cindate = new Date();
        }
        const newDate = new Date(cindate.getTime());
        newDate.setDate(cindate.getDate() + 1);
        const diffTime = Math.abs(newDate - cindate);
        const nights = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        var temproomprice = savePrice;
        var rp = parseFloat(temproomprice * nights);
        var gst = parseFloat((rp * 12) / 100);
        var trp = rp + gst;
        var extraCharges = parseFloat(values.extraCharges);
        var foodBill = parseFloat(values.foodBill);
        var price = parseFloat(rp + extraCharges + foodBill);
        var gSTAmount = parseFloat((price * 12) / 100);
        var finalPrice = price + gSTAmount;
        setValues({
            ...values,
            ['roomPrices']: rp,
            ['roomGST']: gst,
            ['totalRoomPrice']: trp,
            ['price']: price,
            ['extraCharges']: extraCharges,
            ['foodBill']: foodBill,
            ['gSTAmount']: gSTAmount,
            ['finalPrice']: finalPrice,
            ['pendingAmount']: finalPrice,
            ['noOfNights']: nights,
            ['checkoutDate']: newDate,
            ['checkinDate']: cindate,
        });
        return newDate;
    };
    const selectCheckoutDate = (data) => {
        const checkindate = values.checkinDate;
        var checkoutdate = new Date();
        if (data === undefined) {
            checkoutdate = addDays(checkindate)
        }
        else {
            checkoutdate = data;
        }
        if (checkoutdate < checkindate) {
            addDays(checkindate)
            alert("Checkout date should not be less than checkin date");
        } else {
            const diffTime = Math.abs(checkoutdate - checkindate);
            const nights = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            var temproomprice = savePrice;
            var rp = parseFloat(temproomprice * nights);
            var gst = parseFloat((rp * 12) / 100);
            var trp = rp + gst;
            setSaveTotalPrice(trp);
            var extraCharges = parseFloat(values.extraCharges);
            var foodBill = parseFloat(values.foodBill);
            var price = parseFloat(rp + extraCharges + foodBill);
            var gSTAmount = parseFloat((price * 12) / 100);
            var finalPrice = price + gSTAmount;
            setValues({
                ...values,
                ['roomPrices']: rp,
                ['roomGST']: gst,
                ['totalRoomPrice']: trp,
                ['price']: price,
                ['extraCharges']: values.extraCharges,
                ['foodBill']: foodBill,
                ['gSTAmount']: gSTAmount,
                ['finalPrice']: finalPrice,
                ['pendingAmount']: finalPrice,
                ['noOfNights']: nights,
                ['checkoutDate']: checkoutdate,
                ['checkinDate']: checkindate,
            });
        }
    };
    const handleExtraCharges = (e) => {
        const { name, value } = e.target;
        console.log(value)
        const checkindate = values.checkinDate;
        var checkoutdate = values.checkoutDate;
        if (checkoutdate < checkindate) {
            addDays(checkindate)
            alert("Checkout date should not be less than checkin date");
        } else {
            const diffTime = Math.abs(checkoutdate - checkindate);
            const nights = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            var temproomprice = savePrice;
            var rp = parseFloat(temproomprice * nights);
            var gst = parseFloat((rp * 12) / 100);
            var trp = rp + gst;
            setSaveTotalPrice(trp);
            var extraCharges = parseFloat(value);
            var foodBill = parseFloat(values.foodBill);
            var price = parseFloat(rp + extraCharges + foodBill);
            var gSTAmount = parseFloat((price * 12) / 100);
            var finalPrice = price + gSTAmount;
            setValues({
                ...values,
                ['roomPrices']: rp,
                ['roomGST']: gst,
                ['totalRoomPrice']: trp,
                ['price']: price,
                ['foodBill']: foodBill,
                ['extraCharges']: value,
                ['gSTAmount']: gSTAmount,
                ['finalPrice']: finalPrice,
                ['noOfNights']: nights,
                ['checkoutDate']: checkoutdate,
                ['checkinDate']: checkindate,
            });
        }
    };
    const handleTotalRoomPriceChange = (e) => {
        const { name, value } = e.target;
        const checkindate = values.checkinDate;
        var checkoutdate = values.checkoutDate;
        if (checkoutdate < checkindate) {
            addDays(checkindate)
            alert("Checkout date should not be less than checkin date");
        } else {
            const diffTime = Math.abs(checkoutdate - checkindate);
            const nights = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            var noOfRooms = 1;
            if (bookingRoomsData.length > 0) {
                noOfRooms = parseFloat(bookingRoomsData.length);
            }
            var trp = parseFloat(value);
            var gst = parseFloat((trp * 12) / 100);
            var rp = parseFloat((trp - gst));
            var foodBill = parseFloat(values.foodBill);
            var extraCharges = parseFloat(values.extraCharges);
            var price = parseFloat(rp + extraCharges + foodBill);
            var gSTAmount = gst;
            var finalPrice = Math.round((price) + gSTAmount);
            setValues({
                ...values,
                ['roomPrices']: rp,
                ['roomGST']: gst,
                ['totalRoomPrice']: trp,
                ['price']: price,
                ['foodBill']: values.foodBill,
                ['extraCharges']: extraCharges,
                ['gSTAmount']: gSTAmount,
                ['finalPrice']: finalPrice,
                ['pendingAmount']: finalPrice,
                ['noOfNights']: nights,
                ['checkoutDate']: checkoutdate,
                ['checkinDate']: checkindate,
            });
        }
    };
    const handlePaidAmount = (e) => {

        if (e.target.value !== '') {
            const { name, value } = e.target;
            const paidAmount = parseFloat(value);
            if (paidAmount > 0) {
                const pendingAmount = Math.round(parseFloat(values.finalPrice - paidAmount));
                if (values.finalPrice !== 0) {
                    setValues({
                        ...values,
                        ['paidAmount']: paidAmount,
                        ['pendingAmount']: pendingAmount
                    });
                }
                else {
                    alert("Please select the rooms");
                }
            }
        }
    };
    const CheckRooms = (bDate) => {
        setLoading(false);
        setValues({
            ...values,
            ['bookingDate']: bDate
        });
        GetRooms(bDate);
    };
    const GetRooms = (bDate) => {
        setLoading(false);
        axios
            .get(config.APIACTIVATEURL + config.GETROOMSAVAILABILITY + "?BookingDate=" + moment(bDate).format('YYYY-MM-DD'), { ...headerconfig })
            .then((response) => {
                setRooms(response.data.data);
                setLoading(true);
            })
    };
    const applyErrorClass = (field) =>
        field in errors && errors[field] === false ? " form-control-danger" : "";
    useEffect(() => {
        GetRooms(new Date());
        addDays(values.checkinDate);
    }, [])
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Rooms Availability</h4>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xxl-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row h-100">
                                            <div className="col-lg-4">
                                                <label className="form-lable">CHECK AVAILABILITY<span className="AsteriskRed">*</span></label>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="input-group">
                                                    <Flatpickr
                                                        className="form-control border-0 dash-filter-picker shadow"
                                                        options={{
                                                            dateFormat: "d-m-Y"
                                                        }}
                                                        value={values.bookingDate}
                                                        onChange={(selectedDates) => {
                                                            CheckRooms(selectedDates[0])
                                                        }}
                                                        min={minDateValue}
                                                        name="bookingDate"
                                                    />
                                                    <div className="input-group-text bg-primary border-primary text-white">
                                                        <i className="ri-calendar-2-line" />
                                                    </div>
                                                </div>
                                                {errors.checkinDate === false ? (<div className="validationerror">Please enter check-in date </div>) : ('')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body p-0">
                                        <div className="tab-content p-0">
                                            <div className="p-3">
                                                <div className="d-flex flex-column h-100">
                                                    {loading === true ?
                                                        <div className="row h-100">
                                                            {rooms.length > 0 && rooms.map((room, index) =>
                                                                <div className="col-lg-4 col-md-6">
                                                                    {room.status === "BOOKED" ?
                                                                        <div className="card" style={{ backgroundColor: '#0000FF' }}>
                                                                            <div className="card-body">
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="avatar-sm flex-shrink-0">
                                                                                        <span className="avatar-title bg-light text-primary rounded-circle fs-3">
                                                                                            <i className="ri-hotel-bed-fill align-middle" />
                                                                                        </span>
                                                                                    </div>
                                                                                    <Link to={"/bookingdetails/" + room.bookingId} id="RoomId">
                                                                                        <div className="flex-grow-1 ms-3">
                                                                                            <p className="text-uppercase fw-semibold fs-12 text-white mb-1"> {room.roomNo}</p>
                                                                                            <p className="text-white mb-0">{room.roomTypeName}</p>
                                                                                            <span className="text-white">RESERVED</span>
                                                                                        </div>
                                                                                    </Link>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        : room.status === "CHECKIN" ?
                                                                            <div className="card" style={{ backgroundColor: '#FF0000' }}>
                                                                                <div className="card-body">
                                                                                    <div className="d-flex align-items-center">
                                                                                        <Link to={"/checkinbookingdetails/" + room.bookingId} id="RoomId">
                                                                                            <p className="text-uppercase fw-semibold fs-12 text-white mb-1"> {room.roomNo} - {room.roomTypeName}</p>
                                                                                                <span className="text-white">CHECK-OUT</span>
                                                                                                <p className="text-white mb-0">{moment(room.checkOut).format('DD MMM yyyy hh:mm a')}</p>
                                                                                        </Link>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            : bookingRoomsData.find(employee => employee.roomId === room.roomId) ?
                                                                                <div className="card" style={{ backgroundColor: '#CC5500' }}>
                                                                                    <div className="card-body">
                                                                                        <div className="d-flex align-items-center">
                                                                                            <div className="avatar-sm flex-shrink-0">
                                                                                                <span className="avatar-title bg-light text-primary rounded-circle fs-3">
                                                                                                    <i className="ri-hotel-bed-fill align-middle" />
                                                                                                </span>
                                                                                            </div>
                                                                                            <Link onClick={e => alert("Room already selected")} id="RoomId">
                                                                                                <div className="flex-grow-1 ms-3">
                                                                                                    <p className="text-uppercase fw-semibold fs-12 text-white mb-1"> {room.roomNo}</p>
                                                                                                    <p className="text-white mb-0">{room.roomTypeName}</p>
                                                                                                    <span className="text-white">SELECTED</span>
                                                                                                </div>
                                                                                            </Link>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                <div className="card" style={{ backgroundColor: '#008000' }}>
                                                                                    <div className="card-body">
                                                                                        <div className="d-flex align-items-center">
                                                                                            <div className="avatar-sm flex-shrink-0">
                                                                                                <span className="avatar-title bg-light text-primary rounded-circle fs-3">
                                                                                                    <i className="ri-hotel-bed-fill align-middle" />
                                                                                                </span>
                                                                                            </div>
                                                                                            <Link onClick={e => selectRoom(room)} id="RoomId">
                                                                                                <div className="flex-grow-1 ms-3">
                                                                                                    <p className="text-uppercase fw-semibold fs-12 text-white mb-1"> {room.roomNo}</p>
                                                                                                    <p className="text-white mb-0">{room.roomTypeName}</p>
                                                                                                    <span className="text-white">{room.status}</span>
                                                                                                </div>
                                                                                            </Link>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                    }
                                                                </div>
                                                            )}
                                                        </div>
                                                        :
                                                        <div className="alert alert-primary" role="alert">
                                                            <strong>Please Wait <b>Rooms Loading.......</b></strong>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-6">
                                <div className="card">
                                    <div className="card-header align-items-center border-0 d-flex">
                                        <h4 className="card-title mb-0 flex-grow-1">New Booking</h4>
                                    </div>
                                    <div className="card-body p-0">
                                        <div className="tab-content p-0">
                                            <div className="tab-pane active">
                                                <div className="p-3">
                                                    <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                                                        <div className="alert alert-success">
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <div className="mb-3">
                                                                        <label className="form-lable">Guest Name<span className="AsteriskRed">*</span></label>
                                                                        <input type="text" value={values.guestName} name="guestName" onChange={handleInputChange} className={"form-control" + applyErrorClass('guestName')} placeholder="Please Enter Guest Name" />
                                                                        {errors.guestName === false ? (<div className="validationerror">Please enter guest name </div>) : ('')}
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <div className="mb-3">
                                                                        <label className="form-lable">Guest Mobile<span className="AsteriskRed">*</span></label>
                                                                        <input type="text" value={values.guestMobile} name="guestMobile" onChange={handleInputChange} className={"form-control" + applyErrorClass('guestMobile')} placeholder="Please Enter Guest Mobile" />
                                                                        {errors.guestMobile === false ? (<div className="validationerror">Please enter guest mobile </div>) : ('')}
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <div className="mb-3">
                                                                        <label className="form-lable">Guest Email</label>
                                                                        <input type="text" value={values.guestEmail} name="guestEmail" onChange={handleInputChange} className={"form-control" + applyErrorClass('guestName')} placeholder="Please Enter Guest Email" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <div className="mb-3">
                                                                        <label className="form-lable">Guest GST</label>
                                                                        <input type="text" value={values.guestGST} name="guestGST" onChange={handleInputChange} className={"form-control" + applyErrorClass('guestGST')} placeholder="Please Enter Guest GST" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6" />
                                                            </div>
                                                        </div>
                                                        <div className="alert alert-primary">
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <div className="mb-3">
                                                                        <label className="form-lable">Check-in<span className="AsteriskRed">*</span></label>
                                                                        <div className="input-group">
                                                                            <Flatpickr
                                                                                className="form-control border-0 dash-filter-picker shadow"
                                                                                options={{
                                                                                    dateFormat: "d-m-Y"
                                                                                }}
                                                                                value={values.checkinDate}
                                                                                onChange={(selectedDates) => {
                                                                                    addDays(selectedDates[0])
                                                                                }}
                                                                                min={minDateValue}
                                                                                name="checkinDate"
                                                                            />
                                                                            <div className="input-group-text bg-primary border-primary text-white">
                                                                                <i className="ri-calendar-2-line" />
                                                                            </div>
                                                                        </div>
                                                                        {errors.checkinDate === false ? (<div className="validationerror">Please enter check-in date </div>) : ('')}
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <div className="mb-3">
                                                                        <label className="form-lable">Check-out<span className="AsteriskRed">*</span></label>
                                                                        <div className="input-group">
                                                                            <Flatpickr
                                                                                className="form-control border-0 dash-filter-picker shadow"
                                                                                options={{
                                                                                    dateFormat: "d-m-Y"
                                                                                }}
                                                                                value={values.checkoutDate}
                                                                                onChange={(selectedDates) => {
                                                                                    selectCheckoutDate(selectedDates[0])
                                                                                }}
                                                                                minDate="today"
                                                                                name="checkoutDate"
                                                                            />
                                                                            <div className="input-group-text bg-primary border-primary text-white">
                                                                                <i className="ri-calendar-2-line" />
                                                                            </div>
                                                                        </div>
                                                                        {errors.checkoutDate === false ? (<div className="validationerror">Please enter check-out date </div>) : ('')}
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <div className="mb-3">
                                                                        <label className="form-lable">Nights</label>
                                                                        <input type="number" className="form-control" disabled value={values.noOfNights} name="noOfNights" id="noOfNights" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <div className="mb-2">
                                                                        <label asp-for="Adults" className="form-label">Adults<span className="AsteriskRed">*</span></label>
                                                                        <select name="adults" value={values.adults} className="form-select" onChange={handleInputChange}>
                                                                            <option value={1}>1</option>
                                                                            <option value={2}>2</option>
                                                                            <option value={3}>3</option>
                                                                            <option value={4}>4</option>
                                                                            <option value={5}>5</option>
                                                                            <option value={6}>6</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <div className="mb-2">
                                                                        <label asp-for="Kids" className="form-label">Kids<span className="AsteriskRed">*</span></label>
                                                                        <select name="kids" value={values.kids} className="form-select" onChange={handleInputChange}>
                                                                            <option value={1}>1</option>
                                                                            <option value={2}>2</option>
                                                                            <option value={3}>3</option>
                                                                            <option value={4}>4</option>
                                                                            <option value={5}>5</option>
                                                                            <option value={6}>6</option>
                                                                        </select>
                                                                    </div>
                                                                </div>                                                                
                                                                <div className="col-lg-4">
                                                                    <div className="mb-3">
                                                                        <label className="form-lable">Total Room Price<span className="AsteriskRed">*</span></label>
                                                                        <input type="text" value={values.totalRoomPrice} name="totalRoomPrice" onChange={handleTotalRoomPriceChange} className={"form-control" + applyErrorClass('totalRoomPrice')} placeholder="Please Enter Total Room Prices" />
                                                                    </div>
                                                                </div>
                                                                {/* <div className="col-lg-4">
                                                                    <div className="mb-3">
                                                                        <label className="form-lable">Extra Charges</label>
                                                                        <input type="text" value={values.extraCharges} name="extraCharges" onChange={handleExtraCharges} className={"form-control" + applyErrorClass('extraCharges')} placeholder="Please Enter Extra Charges" />
                                                                    </div>
                                                                </div> */}
                                                                <div className="col-lg-4">
                                                                    <div className="mb-2">
                                                                        <label className="form-label">Source<span className="AsteriskRed">*</span></label>
                                                                        <select className="form-select" name="source" value={values.source} onChange={handleInputChange}>
                                                                            <option value="WALKIN">WALKIN</option>
                                                                            <option value="ONLINE">ONLINE</option>
                                                                            <option value="OYO">OYO</option>
                                                                            <option value="OTHERS">OTHERS</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <div className="mb-2">
                                                                        <label className="form-label">Payment Mode<span className="AsteriskRed">*</span></label>
                                                                        <select className="form-select" name="paymentMode" value={values.paymentMode} onChange={handleInputChange}>
                                                                            <option value="CASH">CASH</option>
                                                                            <option value="UPI">UPI</option>
                                                                            <option value="GOOGLEPAY">GOOGLE PAY</option>
                                                                            <option value="PHONEPAY">PHONE PAY</option>
                                                                            <option value="PAYTM">PAYTM</option>
                                                                            <option value="NETBANKING">NET BANKING</option>
                                                                            <option value="CREDITCARD">CREDIT CARD</option>
                                                                            <option value="DEBITCARD">DEBIT CARD</option>
                                                                            <option value="OYO">OYO</option>
                                                                            <option value="OTHERS">OTHERS</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <div className="mb-3">
                                                                        <label className="form-lable">Amount<span className="AsteriskRed">*</span></label>
                                                                        <input type="text" value={values.paidAmount} name="paidAmount" onChange={handlePaidAmount} className={"form-control" + applyErrorClass('paidAmount')} placeholder="Please Enter Amount" />
                                                                        {errors.paidAmount === false ? (<div className="validationerror">Please enter how much guest is paying </div>) : ('')}
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <div className="mb-3">
                                                                        <label className="form-lable">Pending Amount<span className="AsteriskRed">*</span></label>
                                                                        <input type="text" value={values.pendingAmount} name="pendingAmount" onChange={handleInputChange} className={"form-control" + applyErrorClass('pendingAmount')} placeholder="Please Enter Amount" disabled />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <div className="mb-3">
                                                                        <input type="hidden" value={values.roomPrices} name="roomPrices" onChange={handleInputChange} className={"form-control" + applyErrorClass('roomPrices')} placeholder="Please Enter Room Prices" disabled />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <div className="mb-3">
                                                                        <input type="hidden" value={values.roomGST} name="roomGST" onChange={handleInputChange} className={"form-control" + applyErrorClass('roomGST')} placeholder="Please Enter Room GST" disabled />
                                                                    </div>
                                                                </div>
                                                                {/* <div className="col-lg-4">
                                                                    <div className="mb-2">
                                                                        <label className="form-label">Payment Status<span className="AsteriskRed">*</span></label>
                                                                        <select className="form-select" name="paymentStatus" value={values.paymentStatus} onChange={handleInputChange}>
                                                                            <option value="PENDING">PENDING</option>
                                                                            <option value="PAID">PAID</option>                                                                            
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <div className="mb-2">
                                                                        <label className="form-label">Source<span className="AsteriskRed">*</span></label>
                                                                        <select className="form-select" name="source" value={values.source} onChange={handleInputChange}>
                                                                            <option value="WALKIN">WALKIN</option>
                                                                            <option value="ONLINE">ONLINE</option>
                                                                            <option value="OYO">OYO</option>
                                                                            <option value="OTHER">OTHER</option>
                                                                        </select>
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                        <div className="mt-3 pt-2">
                                                            <div className="d-flex mb-2">
                                                                <div className="flex-grow-1">
                                                                    <p className="fs-13 mb-0">Price<span className="text-muted ms-1 fs-11" /></p>
                                                                </div>
                                                                <div className="flex-shrink-0">
                                                                    <input type="text" disabled value={values.price} name="price" onChange={handleInputChange} className={"form-control" + applyErrorClass('price')} placeholder="Please Enter Price" />
                                                                </div>
                                                            </div>
                                                            <div className="d-flex mb-2">
                                                                <div className="flex-grow-1">
                                                                    <p className="fs-13 mb-0">GST<span className="text-muted ms-1 fs-11">(12%)</span></p>
                                                                </div>
                                                                <div className="flex-shrink-0">
                                                                    <input type="text" disabled value={values.gSTAmount} name="gSTAmount" onChange={handleInputChange} className={"form-control" + applyErrorClass('gSTAmount')} placeholder="Please Enter GST" />
                                                                </div>
                                                            </div>
                                                            <div className="d-flex">
                                                                <div className="flex-grow-1">
                                                                    <p className="fs-13 mb-0">Final Price</p>
                                                                </div>
                                                                <div className="flex-shrink-0">
                                                                    <input type="text" disabled value={values.finalPrice} name="finalPrice" onChange={handleInputChange} className={"form-control" + applyErrorClass('finalPrice')} placeholder="Please Enter Final Price" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mt-3 pt-2">
                                                            <div className="hstack gap-2 justify-content-end mb-3 mt-4">
                                                                <button type="submit" className="btn btn-primary">CHECK-IN</button>
                                                                <button type="button" className="btn btn-danger" onClick={resetForm}>CANCEL</button>
                                                                <button type="button" onClick={ReserverBooking} className="btn btn-success">RESERVE</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>{/* end tab pane */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}