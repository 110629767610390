import { Link, useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import config from "../Common/Configurations/APIConfig";
import LayoutHeader from "../Common/Layout/Header";
import LayoutSideBar from "../Common/Layout/Sidebar";
import { handleSuccess, handleError } from "../Common/Layout/CustomAlerts";
import moment from "moment";
const initialFieldValues = {
    bookingRoomDetailId: "00000000-0000-0000-0000-000000000000",
    bookingId: "00000000-0000-0000-0000-000000000000",
    roomId: "00000000-0000-0000-0000-000000000000",
    checkinDate: new Date(),
    checkoutDate: new Date(),
    roomPrice: 0,
    roomGST: 0,
    netPrice: 0,
    gstAmount: 0,
    salePrice: 0,
    totalRoomPrice: 0,
};
const initialPriceFieldValues = {
    bookingRoomPriceId: "00000000-0000-0000-0000-000000000000",
    bookingId: "00000000-0000-0000-0000-000000000000",
    paymentMode: 'CASH',
    amount: 0,
    paymentDate: new Date()
};
const initialBookingFieldValues = {
    bookingId: "00000000-0000-0000-0000-000000000000",
    extraCharges: 0,
    foodBill: 0,
};
export default function BookingDetails() {
    const params = useParams();
    const navigate = useNavigate();
    const [roomPrices, setRoomPrices] = useState([]);
    const [bookingsData, setBookingsData] = useState('');
    const [bookingValues, setBookingValues] = useState(initialBookingFieldValues);
    const [bookingPriceData, setBookingPriceData] = useState('');
    const [rooms, setRooms] = useState([]);
    const [values, setValues] = useState(initialFieldValues);
    const [recordForEdit, setRecordForEdit] = useState(null);
    const [errors, setErrors] = useState({});
    const [isLoaded, setIsLoaded] = useState(false);
    const [priceValues, setPriceValues] = useState(initialPriceFieldValues);
    const [recordForPriceEdit, setRecordForPriceEdit] = useState(null);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    useEffect(() => {
        if (recordForEdit !== null) {
            setValues(recordForEdit);
        }
    }, [recordForEdit]);

    useEffect(() => {
        if (recordForPriceEdit !== null) {
            setRecordForPriceEdit(recordForPriceEdit);
        }
    }, [recordForPriceEdit]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const handleInputBookingChange = (e) => {
        const { name, value } = e.target;
        setBookingValues({
            ...bookingValues,
            [name]: value,
        });
    };

    const handlePriceInputChange = (e) => {
        const { name, value } = e.target;
        setPriceValues({
            ...priceValues,
            [name]: value,
        });
    };
    const handleBookingSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData()
        formData.append("bookingId", params.bookingId)
        formData.append("foodBill", bookingValues.foodBill)
        formData.append("extraCharges", bookingValues.extraCharges)
        axios.post(config.APIACTIVATEURL + config.BOOKINGCHARGES, formData, { ...headerconfig })
            .then((res) => {
                if (res.data.statusCode === 200) {
                    handleSuccess("Record Updated");
                    resetForm();
                    GetBooking();
                }
                else {
                    handleError(res.data.message);
                }
            });
    };
    const handlePriceChange = (e) => {
        const { name, value } = e.target;
        axios
            .get(config.APIACTIVATEURL + config.GETROOMPRICEBYROOMID + "/" + value, { ...headerconfig })
            .then((response) => {
                setValues({
                    ...values,
                    ['roomId']: value,
                    ['roomPrice']: response.data.data.netPrice,
                    ['roomGST']: response.data.data.gst,
                    ['totalRoomPrice']: response.data.data.salePrice
                });
            });

    }
    const pricevalidate = () => {
        let temp = {};
        temp.paymentMode = values.paymentMode === "00000000-0000-0000-0000-000000000000" ? false : true;
        temp.amount = values.amount === 0 ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handlePriceSubmit = (e) => {
        e.preventDefault();
        if (priceValues.amount > bookingsData.pendingAmount) {
            alert("Amount should not be greater than pending amount");
        }
        else if (pricevalidate()) {
            const formData = new FormData()
            formData.append("bookingRoomPriceId", priceValues.bookingRoomPriceId)
            formData.append("bookingId", params.bookingId)
            formData.append("paymentMode", priceValues.paymentMode)
            formData.append("amount", priceValues.amount)
            addOrEditPrice(formData);
        }
    };
    const addOrEditPrice = (formData) => {
        if (formData.get('bookingRoomPriceId') === "00000000-0000-0000-0000-000000000000") {
            applicationAPI()
                .pricecreate(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Created");
                        resetForm();
                        GetBooking();
                        GetPriceDetails();
                    }
                    else {
                        handleError(res.data.message);
                    }
                });
        } else {
            applicationAPI()
                .priceupdate(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Updated");
                        resetForm();
                        GetBooking();
                        GetPriceDetails();
                    }
                    else {
                        handleError(res.data.message);
                    }
                });
        }
    };
    const validate = () => {
        let temp = {};
        temp.roomId = values.roomId === "00000000-0000-0000-0000-000000000000" ? false : true;
        temp.roomId = values.roomId === undefined ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            const formData = new FormData()
            formData.append("bookingRoomDetailId", values.bookingRoomDetailId)
            formData.append("bookingId", params.bookingId)
            formData.append("roomId", values.roomId)
            formData.append("checkinDate", bookingsData.checkinDate)
            formData.append("checkoutDate", bookingsData.checkoutDate)
            formData.append("roomPrice", values.roomPrice)
            formData.append("roomGST", values.roomGST)
            formData.append("totalRoomPrice", values.totalRoomPrice)
            addOrEdit(formData);
        }
    };
    const applicationAPI = () => {
        return {
            create: (newrecord) =>
                axios.post(config.APIACTIVATEURL + config.CREATEBOOKINGROOMDETAIL, newrecord, { ...headerconfig }),
            update: (updateRecord) =>
                axios.put(config.APIACTIVATEURL + config.UPDATEBOOKINGROOMDETAIL, updateRecord, { ...headerconfig }),
            pricecreate: (newrecord) =>
                axios.post(config.APIACTIVATEURL + config.CREATEBOOKINGPRICE, newrecord, { ...headerconfig }),
            priceupdate: (updateRecord) =>
                axios.put(config.APIACTIVATEURL + config.UPDATEBOOKINGPRICE, updateRecord, { ...headerconfig })
        };
    };
    const addOrEdit = (formData) => {
        if (formData.get('bookingRoomDetailId') === "00000000-0000-0000-0000-000000000000") {
            applicationAPI()
                .create(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Created");
                        resetForm();
                        GetBooking();
                        GetBookingRooms();
                    }
                    else {
                        handleError(res.data.message);
                    }
                });
        } else {
            applicationAPI()
                .update(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Updated");
                        resetForm();
                        GetBooking();
                        GetBookingRooms();
                    }
                    else {
                        handleError(res.data.message);
                    }
                });
        }
    };
    const resetForm = () => {
        setValues(initialFieldValues);
        setPriceValues(initialPriceFieldValues)
        setBookingValues(initialBookingFieldValues)
        setErrors({});
    };
    const showEditDetails = (data) => {
        setRecordForEdit(data);
    };
    const onCheckout = (e, id) => {
        e.preventDefault();
        if (bookingsData.pendingAmount === 0) {
            if (window.confirm('Are you sure to checkout the room?')) {
                axios.post(config.APIACTIVATEURL + config.BOOKINGCHECKOUT + "/" + id, { ...headerconfig })
                    .then((response) => {
                        if (response.data.statusCode === 200) {
                            handleSuccess(response.data.data);
                            navigate("/checkoutbookingdetails/" + id);
                        }
                        else {
                            handleError(response.data.data);
                            GetBooking();
                        }
                    });
            }
        }
        else {
            alert("Payment not yet cleared. Please check");
        }
    }
    const onCheckIn = (e, id) => {
        e.preventDefault();
        console.log(roomPrices)
        if (roomPrices.length === 0) {
            alert("Please allocate rooms");
        }
        else if (window.confirm('Are you sure to checkin?')) {
            axios.post(config.APIACTIVATEURL + config.CHECKINBOOKING + "/" + id, { ...headerconfig })
                .then((response) => {
                    if (response.data.statusCode === 200) {
                        handleSuccess(response.data.data);
                        GetBooking();
                        GetBookingRooms();
                    }
                    else {
                        handleError(response.data.data);
                        GetBooking();
                        GetBookingRooms();
                    }
                });
        }
    }
    const GetBooking = () => {
        axios
            .get(config.APIACTIVATEURL + config.GETBOOKINGID + "/" + params.bookingId, { ...headerconfig })
            .then((response) => {
                setBookingsData(response.data.data);
                GetRooms(response.data.data.bookingDate);
                setValues({
                    ...values,
                    ['bookingId']: response.data.data.bookingId,
                    ['roomId']: response.data.data.roomId,
                    ['checkinDate']: response.data.data.checkinDate,
                    ['checkoutDate']: response.data.data.checkoutDate
                });
            });
    };
    const GetRooms = (bDate) => {
        axios
            .get(config.APIACTIVATEURL + config.GETROOMSAVAILABILITY + "?BookingDate=" + moment(bDate).format('YYYY-MM-DD'), { ...headerconfig })
            .then((response) => {
                setRooms(response.data.data);
                setIsLoaded(true);
            });
    };
    const GetBookingRooms = () => {
        axios
            .get(config.APIACTIVATEURL + config.GETROOMDETAILBYBOOKINGID + "/" + params.bookingId, { ...headerconfig })
            .then((response) => {
                setRoomPrices(response.data.data);
            });
    };
    const GetPriceDetails = () => {
        axios
            .get(config.APIACTIVATEURL + config.GETROOMPRICEBYBOOKINGID + "/" + params.bookingId, { ...headerconfig })
            .then((response) => {
                setBookingPriceData(response.data.data);
            });
    };
    const applyErrorClass = (field) =>
        field in errors && errors[field] === false ? " form-control-danger" : "";
    useEffect(() => {
        GetBooking();
        GetBookingRooms();
        GetPriceDetails();
    }, [])
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Booking Details</h4>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header align-items-center border-0 d-flex">
                            </div>
                            <div className="card-body p-0">
                                <table className="table table-bordered table-nowrap mb-0">
                                    <tr>
                                        <th className="text-nowrap p-3" scope="row">
                                            Reservation No
                                        </th>
                                        <td>
                                            {bookingsData.reservationNo}
                                        </td>
                                        <th className="text-nowrap" scope="row">
                                            Adults
                                        </th>
                                        <td>
                                            {bookingsData.adults}
                                        </td>
                                        <th className="text-nowrap" scope="row">
                                            Kids
                                        </th>
                                        <td>
                                            {bookingsData.kids}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="text-nowrap p-3" scope="row">
                                            Check-In Date
                                        </th>
                                        <td>
                                            {bookingsData.bookingStatus == "BOOKED" ? <span className="badge bg-danger"> NOT YET CHECK-IN</span>
                                                : moment(bookingsData.checkinDate).format('DD/MM/YYYY HH:mm a')}
                                        </td>
                                        <th className="text-nowrap" scope="row">
                                            Check-Out Date
                                        </th>
                                        <td>
                                            {bookingsData.bookingStatus !== "CHECKOUT" ? <span className="badge bg-danger"> NOT YET CHECK-OUT</span>
                                                : moment(bookingsData.checkoutDate).format('DD/MM/YYYY HH:mm a')}
                                        </td>
                                        <th className="text-nowrap" scope="row">
                                            Booking Date
                                        </th>
                                        <td>
                                            {moment(bookingsData.bookingDate).format('DD/MM/YYYY HH:mm a')}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="text-nowrap p-3" scope="row">
                                            Rooms
                                        </th>
                                        <td>
                                            {bookingsData.noOfRooms}
                                        </td>
                                        <th className="text-nowrap" scope="row">
                                            Nights
                                        </th>
                                        <td>
                                            {bookingsData.noOfNights}
                                        </td>
                                        <th className="text-nowrap" scope="row">
                                            Payment Mode
                                        </th>
                                        <td>
                                            {bookingsData.paymentMode}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="text-nowrap p-3" scope="row">
                                            Guest Name
                                        </th>
                                        <td>
                                            {bookingsData.guestName}
                                        </td>
                                        <th className="text-nowrap" scope="row">
                                            Guest Mobile
                                        </th>
                                        <td>
                                            {bookingsData.guestMobile}
                                        </td>
                                        <th className="text-nowrap" scope="row">
                                            Guest Email
                                        </th>
                                        <td>
                                            {bookingsData.guestEmail}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="text-nowrap p-3" scope="row">
                                            Room Prices
                                        </th>
                                        <td>
                                            {bookingsData.roomPrices} INR
                                        </td>
                                        <th className="text-nowrap" scope="row">
                                            Extra Charges
                                        </th>
                                        <td>
                                            {bookingsData.extraCharges} INR
                                        </td>
                                        <th className="text-nowrap p-3" scope="row">
                                            Food Bill
                                        </th>
                                        <td>
                                            {bookingsData.foodBill} INR
                                        </td>
                                    </tr>
                                    <tr>                                        
                                        <th className="text-nowrap p-3" scope="row">
                                            GST(12%)
                                        </th>
                                        <td>
                                            {bookingsData.gstAmount} INR
                                        </td>
                                        <th className="text-nowrap" scope="row">
                                            Final Price
                                        </th>
                                        <td>
                                            {bookingsData.finalPrice} INR
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="text-nowrap p-3" scope="row">
                                            Total Paid
                                        </th>
                                        <td>
                                            {Math.round(bookingsData.paidAmount)} INR
                                        </td>
                                        <th className="text-nowrap" scope="row">
                                            Total Pending
                                        </th>
                                        <td>
                                            {Math.round(bookingsData.pendingAmount)} INR
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="text-nowrap p-3" scope="row">
                                            Payment Status
                                        </th>
                                        <td>
                                            <span className="badge bg-success">
                                                {bookingsData.paymentStatus}
                                            </span>
                                        </td>
                                        <th className="text-nowrap" scope="row">
                                            Booking Status
                                        </th>
                                        <td>
                                            <span className="badge bg-sm bg-success">
                                                {bookingsData.bookingStatus}
                                            </span>
                                        </td>
                                        <th className="text-nowrap" scope="row">
                                            Source Status
                                        </th>
                                        <td>
                                            <span className="badge bg-sm bg-success">
                                                {bookingsData.source}
                                            </span>
                                        </td>
                                        <td>
                                            <div className="hstack gap-2 justify-content-end mb-3 mt-4">
                                                {bookingsData.bookingStatus === "BOOKED" ?
                                                    <Link type="submit" className="btn btn-primary w-100" onClick={e => onCheckIn(e, bookingsData.bookingId)}><i className="mdi mdi-logout" />CHECK IN</Link>
                                                    :
                                                    bookingsData.bookingStatus === "CHECKIN" ?
                                                        <Link type="submit" className="btn btn-primary w-100" onClick={e => onCheckout(e, bookingsData.bookingId)}><i className="mdi mdi-logout" />CHECK OUT</Link>
                                                        : ""
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                                <div className="alert alert-primary">
                                    <form onSubmit={handleBookingSubmit} autoComplete="off" noValidate>
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <div className="row">
                                                    <div className="col-lg-3">
                                                        <div className="mb-3">
                                                            <label htmlFor="foodBill" className="form-label">Food Bill</label>
                                                            <input type="number" value={bookingValues.foodBill} name="foodBill" placeholder="0" className="form-control" onChange={handleInputBookingChange} />
                                                            {errors.foodBill === false ? (<div className="validationerror">Please enter foodBill </div>) : ('')}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="mb-3">
                                                            <label htmlFor="extraCharges" className="form-label">Extra Charges</label>
                                                            <input type="number" value={bookingValues.extraCharges} name="extraCharges" placeholder="0" className="form-control" onChange={handleInputBookingChange} />
                                                        </div>
                                                    </div>
                                                    {bookingsData.bookingStatus !== "CHECKOUT" ?
                                                        <div className="col-lg-3">
                                                            <div className="hstack gap-2 justify-content-end mb-3 mt-4">
                                                                <button type="submit" className="btn btn-primary w-100">Submit</button>
                                                                <button type="button" className="btn btn-danger w-100" onClick={resetForm}>Cancel</button>
                                                            </div>
                                                        </div> : ""
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                        <div className="row">
                            <div className="col-xl-6">
                                <div className="alert alert-success">
                                    <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <div className="mb-3">
                                                    <label htmlFor="roomId" className="form-label">Rooms</label>
                                                    {isLoaded ?
                                                        <select name="roomId" value={values.roomId} onChange={handlePriceChange} className={"form-control" + applyErrorClass('roomId')}>
                                                            <option value="00000000-0000-0000-0000-000000000000">Please Select</option>
                                                            {rooms.map(rt =>
                                                                <option value={rt.roomId}>{rt.roomNo}({rt.roomTypeName})</option>
                                                            )}
                                                        </select>
                                                        :
                                                        <select name="roomId" value={values.roomId} onChange={handlePriceChange} className={"form-control" + applyErrorClass('roomId')} disabled>
                                                            <option value="00000000-0000-0000-0000-000000000000">Please Select</option>
                                                            {rooms.map(rt =>
                                                                <option value={rt.roomId}>{rt.roomNo}({rt.roomTypeName})</option>
                                                            )}
                                                        </select>
                                                    }
                                                    {errors.roomId === false ? (<div className="validationerror">Please select room </div>) : ('')}
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="mb-3">
                                                    <label htmlFor="roomPrice" className="form-label">Room Price</label>
                                                    <input type="number" value={values.roomPrice} name="roomPrice" placeholder="0" disabled className="form-control" />
                                                    {errors.roomPrice === false ? (<div className="validationerror">Please enter room price </div>) : ('')}
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="mb-3">
                                                    <label htmlFor="roomGST" className="form-label">GST(12%)</label>
                                                    <input type="text" disabled value={values.roomGST} name="roomGST" placeholder="0" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="mb-3">
                                                    <label htmlFor="totalRoomPrice" className="form-label">Total Room Price</label>
                                                    <input type="text" disabled value={values.totalRoomPrice} name="totalRoomPrice" onChange={handleInputChange} className={"form-control" + applyErrorClass('totalRoomPrice')} placeholder="0" />
                                                </div>
                                            </div>
                                            <div className="col-lg-9"></div>
                                            {bookingsData.bookingStatus !== "CHECKOUT" ?
                                                <div className="col-lg-3">
                                                    <div className="hstack gap-2 justify-content-end mb-3 mt-4">
                                                        <button type="submit" className="btn btn-primary w-100">Submit</button>
                                                        <button type="button" className="btn btn-danger w-100" onClick={resetForm}>Cancel</button>
                                                    </div>
                                                </div> : ""}
                                        </div>
                                    </form>
                                    <div className="card card-height-100">
                                        <div className="card-header align-items-center d-flex">
                                            <h4 className="card-title mb-0 flex-grow-1">Rooms</h4>
                                        </div>{/* end card header */}
                                        <div className="card-body">
                                            <div className="table-responsive table-card">
                                                <table className="table align-middle" id="customerTable">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>Id</th>
                                                            <th>Room No</th>
                                                            <th>Net Price</th>
                                                            <th>GST</th>
                                                            <th>Sale Price</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {roomPrices.length > 0 && roomPrices.map((rp, index) =>
                                                            <tr key={rp.bookingRoomDetailId}>
                                                                <td>{index + 1}</td>
                                                                <td className="id">{rp.roomNo}</td>
                                                                <td className="id">{rp.roomPrice}</td>
                                                                <td className="id">{rp.roomGST}</td>
                                                                <td className="id">{rp.totalRoomPrice}</td>
                                                                <td>
                                                                    {bookingsData.bookingStatus !== "CHECKOUT" ?
                                                                        <ul className="list-inline hstack gap-2 mb-0">
                                                                            <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Edit">
                                                                                <Link className="edit-item-btn" onClick={e => showEditDetails(rp)}><i className="ri-pencil-fill align-bottom text-muted" /></Link>
                                                                            </li>
                                                                        </ul>
                                                                        : ""}
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="alert alert-danger">
                                    <form onSubmit={handlePriceSubmit} autoComplete="off" noValidate>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="mb-3">
                                                    <label className="form-label">Payment Mode<span className="AsteriskRed">*</span></label>
                                                    <select className="form-select" name="paymentMode" value={priceValues.paymentMode} onChange={handlePriceInputChange}>
                                                        <option value="CASH">CASH</option>
                                                        <option value="UPI">UPI</option>
                                                        <option value="GOOGLEPAY">GOOGLE PAY</option>
                                                        <option value="PHONEPAY">PHONE PAY</option>
                                                        <option value="PAYTM">PAYTM</option>
                                                        <option value="NETBANKING">NET BANKING</option>
                                                        <option value="CREDITCARD">CREDIT CARD</option>
                                                        <option value="DEBITCARD">DEBIT CARD</option>
                                                        <option value="OYO">OYO</option>
                                                        <option value="OTHERS">OTHERS</option>
                                                    </select>
                                                    {errors.paymentMode === false ? (<div className="validationerror">Please enter payment mode </div>) : ('')}
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="mb-3">
                                                    <label htmlFor="amount" className="form-label">Amount</label>
                                                    <input type="text" value={priceValues.amount} name="amount" onChange={handlePriceInputChange} placeholder="0" className="form-control" />
                                                    {errors.amount === false ? (<div className="validationerror">Please enter amount </div>) : ('')}
                                                </div>
                                            </div>
                                            {bookingsData.pendingAmount !== 0 ?
                                                <div className="col-lg-3">
                                                    <div className="hstack gap-2 justify-content-end mb-3 mt-4">
                                                        <button type="submit" className="btn btn-primary w-100">Submit</button>
                                                    </div>
                                                </div>
                                                : ""}
                                        </div>
                                    </form>
                                    <div className="card card-height-100">
                                        <div className="card-header align-items-center d-flex">
                                            <h4 className="card-title mb-0 flex-grow-1">Payments</h4>
                                        </div>{/* end card header */}
                                        <div className="card-body">
                                            <div className="table-responsive table-card">
                                                <table className="table align-middle" id="customerTable">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th className="text-nowrap p-3" scope="row">
                                                                Id
                                                            </th>
                                                            <th className="text-nowrap p-3" scope="row">
                                                                Amount
                                                            </th>
                                                            <th className="text-nowrap p-3" scope="row">
                                                                Payment Mode
                                                            </th>
                                                            <th className="text-nowrap p-3" scope="row">
                                                                Payment Date
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {bookingPriceData.length > 0 && bookingPriceData.map((rp, index) =>
                                                            <tr key={rp.bookingRoomPriceId}>
                                                                <td>{index + 1}</td>
                                                                <td className="text-nowrap p-3" scope="row">
                                                                    {rp.amount} INR
                                                                </td>
                                                                <td className="text-nowrap p-3" scope="row">
                                                                    {rp.paymentMode}
                                                                </td>
                                                                <td className="text-nowrap p-3" scope="row">
                                                                    {moment(rp.paymentDate).format('DD/MM/YYYY HH:mm a')}
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}