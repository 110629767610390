import config from '../Common/Configurations/APIConfig';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import auth from '../Common/Configurations/Auth';
import { handleSuccess, handleError } from '../Common/Layout/CustomAlerts';
const initialLoginValues = {
  email: '',
  password: ''
};
export default function Login() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [values, setValues] = useState(initialLoginValues);
  const [errors, setErrors] = useState({});
  const [btnSubmit, setBtnSubmit] = useState(true);

  const applicationAPI = (url = config.APIACTIVATEURL + config.LOGINUSER) => {
    return {
      userlogin: (newRecord) => axios.post(url, newRecord),
    };
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const validate = () => {
    let temp = {};
    temp.email = values.email == '' ? false : true;
    temp.password = values.password == '' ? false : true;
    setErrors(temp);
    return Object.values(temp).every((x) => x == true);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        setBtnSubmit(false);
        const formData = new FormData()
        formData.append("email", values.email)
        formData.append("password", values.password)
        checkUser(formData);
      } catch (err) {
        setBtnSubmit(true);
        handleError('Please check the mandatory fields');
      }
    } else {
      setBtnSubmit(true);
      handleError('Please check the mandatory fields');
    }
  };
  const checkUser = (loginData) => {
    applicationAPI()
      .userlogin(loginData)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setBtnSubmit(true);
          values.email = '';
          values.password = '';
          console.log(res.data)
          auth.ulogin(() => {
            localStorage.setItem('userId', res.data.userId);
            localStorage.setItem('name', res.data.role);
            localStorage.setItem('userToken', res.data.token);
            localStorage.setItem('tokenexpiration', res.data.expiration);
            {
              navigate('/dashboard');
            }
          });
        } else {
          handleError(res.data.data);
          setBtnSubmit(true);
        }
      })
      .catch(function (error) {
        handleError(error);
        setBtnSubmit(true);
      });
  };
  useEffect(() => {
    if (localStorage.getItem('userToken') !== '') {
      if (CheckExpirationTime()) {
        navigate('/dashboard');
      } else {
        navigate('/login');
      }
    } else {
      navigate('/login');
    }
  }, []);
  function CheckExpirationTime() {
    if (localStorage.getItem('tokenexpiration') !== '') {
      const expiredate = new Date(localStorage.getItem('tokenexpiration'));
      const localdate = new Date();
      if (expiredate > localdate) {
        return true;
      }
    }
    return false;
  }
  const applyErrorClass = (field) =>
    field in errors && errors[field] == false ? ' form-control-danger' : '';
  return (
    <div className="auth-back-cover">
      <div className="auth-page-wrapper py-5 d-flex justify-content-center align-items-center min-vh-100">
        {/* auth-page content */}
        <div className="auth-page-content overflow-hidden mt-lg-4">
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <div className="card overflow-hidden rounded-4 loginbox">
                  <div className="row g-0">
                    <div className="p-lg-5 p-4">
                      <div
                        className="text-primary"
                        style={{ fontSize: '30px', textAlign: 'center' }}
                      >
                        HOTEL IGLOOS RMS
                      </div>
                      <p className="text-muted" style={{ textAlign: 'center' }}>
                        Sign in to continue...
                      </p>
                      <div className="mt-4">
                        <form
                          onSubmit={handleSubmit}
                          autoComplete="off"
                          noValidate
                        >
                          <div className="mb-3">
                            <label htmlFor="email" className="form-label">
                              Email
                            </label>
                            <input
                              className={
                                'form-control' + applyErrorClass('email')
                              }
                              name="email"
                              type="text"
                              value={values.email}
                              onChange={handleInputChange}
                              placeholder="Enter Email"
                            />
                            {errors.email === false ? (
                              <div className="validationerror">
                                Please enter email
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="password-input"
                            >
                              Password
                            </label>
                            <div className="position-relative auth-pass-inputgroup mb-3">
                              <input
                                className={
                                  'form-control' + applyErrorClass('password')
                                }
                                name="password"
                                type="password"
                                value={values.password}
                                onChange={handleInputChange}
                                placeholder="Password"
                              />
                              {errors.password === false ? (
                                <div className="validationerror">
                                  Please enter password
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                          <div className="mt-4">
                            {btnSubmit === true ? (
                              <button
                                type="submit"
                                className="btn btn-success w-100"
                              >
                                Sign In
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-primary w-100"
                                disabled
                              >
                                Please wait...
                              </button>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                    {/* end col */}
                  </div>
                  {/* end row */}
                </div>
                {/* end card */}
              </div>
              <p className="display-4 text-success position-absolute top-50 start-50"></p>
              <p className="display-5 text-success logincaption"></p>
              {/* end col */}
            </div>
            {/* end row */}
          </div>
          {/* end container */}
        </div>
        {/* end auth page content */}
        {/* footer */}
        <footer className="footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="text-center d-flex justify-content-center">
                  <p className="mb-0">
                    © HOTEL IGLOOS{' '}
                    <i className="mdi mdi-heart text-danger" /> 2023
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
        {/* end Footer */}
      </div>
    </div>
  );
}
