import { Link } from 'react-router-dom';

export default function LayoutSideBar() {
  return (
    <div className="app-menu navbar-menu">
      {/* LOGO */}
      <div className="navbar-brand-box">
        {/* Dark Logo*/}
        <Link to={"/"} className="logo logo-light">
          <span className="logo-lg" style={{ color: "#fff", fontSize: "20px" }}>
            HOTEL IGLOOS
          </span>
        </Link>
        <button
          type="button"
          className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
          id="vertical-hover"
        >
          <i className="ri-record-circle-line" />
        </button>
      </div>
      <div id="scrollbar">
        <div className="container-fluid">
          <div id="two-column-menu"></div>
          <ul className="navbar-nav" id="navbar-nav">
          <li className="nav-item">
              <Link className="nav-link menu-link" to={'/dashboard'}>
                <i className="ri-chat-new-line"></i>
                <span data-key="t-dashboards">New Booking</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link menu-link" to={'/bookings'}>
                <i className="ri-folder-chart-line"></i>
                <span data-key="t-dashboards">Upcoming</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link menu-link" to={'/checkins'}>
                <i className="ri-key-fill"></i>
                <span data-key="t-dashboards">Check-Ins</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link menu-link" to={'/checkouts'}>
                <i className="ri-key-fill"></i>
                <span data-key="t-dashboards">Check-Outs</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link menu-link" to={'/oyobookings'}>
                <i className="ri-folder-chart-line"></i>
                <span data-key="t-dashboards">OYO Bookings</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link menu-link" to={'/transactions'}>
                <i className="ri-bar-chart-2-line"></i>
                <span data-key="t-dashboards">Transactions</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link menu-link" to={'/cashtransactions'}>
                <i className="ri-bar-chart-line"></i>
                <span data-key="t-dashboards">GST Report</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link menu-link" to={'/rooms'}>
                <i className=" ri-store-2-line"></i>
                <span data-key="t-dashboards">Rooms</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link menu-link" to={'/roomprices'}>
                <i className=" ri-hotel-bed-fill"></i>
                <span data-key="t-dashboards">Room Prices</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link menu-link" to={'/roomtypes'}>
                <i className="ri-store-line"></i>
                <span data-key="t-dashboards">Room Types</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link menu-link" to={'/bedtypes'}>
                <i className="ri-hotel-bed-line"></i>
                <span data-key="t-dashboards"> Bed Types</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link menu-link" to={'/amenities'}>
                <i className="las la-user-check"></i>
                <span data-key="t-dashboards">Amenities</span>
              </Link>
            </li>            
            <li className="nav-item">
              <Link to={"/logout"} className="nav-link menu-link"><i className="mdi mdi-logout" /> <span className="align-middle" data-key="t-logout">Logout</span></Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="sidebar-background" />
    </div>
  );
}
