import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import config from "../Common/Configurations/APIConfig";
import { handleSuccess, handleError } from "../Common/Layout/CustomAlerts";
import LayoutHeader from "../Common/Layout/Header";
import LayoutSideBar from "../Common/Layout/Sidebar";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import * as XLSX from 'xlsx';
export default function CashTransactions() {
    const [bookingsData, setBookingsData] = useState([]);
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [totalNetPrice, setTotalNetPrice] = useState(0);
    const [totalPaid, setTotalPaid] = useState(0);
    const [totalPending, setTotalPending] = useState(0);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    const addDays = (cindate) => {
        if (cindate === undefined) {
            cindate = new Date();
        }
        const newDate = new Date(cindate.getTime());
        if (newDate > toDate) {
            newDate.setDate(cindate.getDate() + 1);
            setToDate(newDate);
        }
        setFromDate(cindate);

        return newDate;
    };
    const selectToDate = (data) => {
        const checkindate = fromDate;
        var checkoutdate = new Date();
        if (data === undefined) {
            checkoutdate = addDays(checkindate)
        }
        else {
            checkoutdate = data;
        }
        if (checkoutdate < checkindate) {
            alert("To Date should be greater than are equal to From Date")
            addDays(checkindate);
        }
        else {
            setFromDate(checkindate);
            setToDate(checkoutdate);
        }

    };
    const exportToExcel = () => {
        var fileName = "GSTReport-" + moment(fromDate).format('DDMMYYYY') + "-" + moment(toDate).format('DDMMYYYY');
        // Create a new workbook
        const wb = XLSX.utils.book_new();
        // Convert the JSON data to a worksheet
        const ws = XLSX.utils.json_to_sheet(bookingsData);
        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        // Save the workbook as an Excel file
        XLSX.writeFile(wb, `${fileName}.xlsx`);
    };
    const GetBookings = () => {
        setBookingsData('');
        setTotalNetPrice(0);
        setTotalPaid(0);
        setTotalPending(0);
        axios
        .get(config.APIACTIVATEURL + config.GETGSTREPORT + "?fromDate=" + moment(fromDate).format('YYYY-MM-DD') + "&toDate=" + moment(toDate).format('YYYY-MM-DD') + "", { ...headerconfig })
            .then((response) => {
                setBookingsData(response.data.data);
                var data = response.data.data;
                setTotalNetPrice(data.reduce((a, v) => a = a + v.amount, 0))
                setTotalPaid(data.reduce((a, v) => a = a + v.gst, 0))
            });
    };
    const calculateTax = (price) => {
        const taxRate = 0.12; // 18%
        const taxAmount = price * taxRate;
        return (taxAmount).toFixed(2);
      };
    useEffect(() => {
        GetBookings();
    }, [])
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">GST Report</h4>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card card-height-100">
                                    <div class="row mb-3 p-3">
                                        <div class="col-lg-3">
                                            <label for="nameInput" class="form-label">FromDate</label>
                                            <div className="input-group">
                                                <Flatpickr
                                                    className="form-control border-0 dash-filter-picker shadow"
                                                    options={{
                                                        dateFormat: "d-m-Y"
                                                    }}
                                                    value={fromDate}
                                                    onChange={(selectedDates) => {
                                                        addDays(selectedDates[0])
                                                    }}
                                                    name="fromDate"
                                                />
                                                <div className="input-group-text bg-primary border-primary text-white">
                                                    <i className="ri-calendar-2-line" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-3">
                                            <label class="form-label">ToDate</label>
                                            <div className="input-group">
                                                <Flatpickr
                                                    className="form-control border-0 dash-filter-picker shadow"
                                                    options={{
                                                        dateFormat: "d-m-Y"
                                                    }}
                                                    value={toDate}
                                                    onChange={(selectedDates) => {
                                                        selectToDate(selectedDates[0])
                                                    }}
                                                    name="toDate"
                                                />
                                                <div className="input-group-text bg-primary border-primary text-white">
                                                    <i className="ri-calendar-2-line" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="hstack gap-2 justify-content-end mb-3 mt-4">
                                                <button onClick={GetBookings} type="button" className="btn btn-success"><i className="ri-search-line align-middle me-1" /> Search</button>

                                                <button onClick={exportToExcel} type="button" className="btn btn-primary"><i className="ri-arrow-down-line align-middle me-1" /> Download</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive table-card">
                                            <table className="table align-middle" id="customerTable">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th>Id</th>
                                                        <th>Reservation No</th>
                                                        <th>Check-in</th>
                                                        <th>Check-out</th>
                                                        <th>Booking Date</th>
                                                        <th>Name</th>
                                                        <th>Amount</th>
                                                        <th>GST</th>
                                                        <th>Mode</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    {bookingsData.length > 0 && bookingsData.map((bd, index) =>
                                                        <tr key={bd.bookingId}>
                                                            <td>{index + 1}</td>
                                                            <td className="id">{bd.reservationNo}</td>
                                                            <td className="id">{moment(bd.checkinDate).format('DD/MM/YYYY')}</td>
                                                            <td className="id">{moment(bd.checkoutDate).format('DD/MM/YYYY')}</td>
                                                            <td className="id">{moment(bd.bookingDate).format('DD/MM/YYYY HH:mm a')}</td>
                                                            <td className="id">{bd.guestName}</td>
                                                            <td className="id">{bd.amount.toFixed(2)}</td>
                                                            <td className="id">{bd.gst.toFixed(2)}</td>
                                                            <td className="id">{bd.paymentMode}</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td colSpan={5}></td>
                                                        <th>Total : </th>
                                                        <th>{totalNetPrice.toFixed(2)}</th>
                                                        <th>{totalPaid.toFixed(2)}</th>
                                                        <th></th>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div> {/* .card*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}